import { APITypes } from "@lib/api.types";
import { DEFAULT_LANGUAGE, SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { useMemo } from "react";
import { INavigationItem } from "./Navigation.types";
import {
  createNavigationItem,
  getBaseFooter,
  getBaseMobileNavigation,
  getBaseNavigation,
} from "./shared";
import useGetNavigationBar from "./useGetNavigationBar";

export const useCreateNavigationMenu = (locale: SupportedLocales) => {
  const { navigationBarData } = useGetNavigationBar(locale);
  return useCreateMenu(locale, navigationBarData, getBaseNavigation);
};

export const useCreateMobileNavigationMenu = (locale: SupportedLocales) => {
  const { navigationBarData } = useGetNavigationBar(locale);
  return useCreateMenu(locale, navigationBarData, getBaseMobileNavigation);
};

export const useCreateFooterNavigationMenu = (
  locale: SupportedLocales,
  global: APITypes.Global | null
) => {
  const { navigationBarData } = useGetNavigationBar(locale);
  return useCreateMenu(locale, navigationBarData, (locale: SupportedLocales) =>
    getBaseFooter(
      locale,
      global,
      navigationBarData?.cta_text ?? "",
      navigationBarData?.request_quote_text ?? ""
    )
  );
};

function useCreateMenu(
  locale: SupportedLocales,
  navigationBarData: APITypes.Components.NavigationBar | null,
  getBase: (locale: SupportedLocales) => INavigationItem[]
) {
  const navigationMenu = useMemo(() => {
    if (!navigationBarData) return getBase(locale);
    if (navigationBarData) {
      const navigationClone = [...getBase(locale)];
      const solutionsSubMenuIndex = navigationClone.findIndex((navItem) => {
        return navItem.url === getLocalizedPath(Paths.Solutions, locale);
      });
      if (solutionsSubMenuIndex !== undefined) {
        if (navigationClone[solutionsSubMenuIndex]) {
          navigationClone[solutionsSubMenuIndex].children =
            navigationBarData.solutions.reduce(
              (allSolutions, featuredSolution) => {
                if (
                  !featuredSolution?.solution ||
                  !featuredSolution?.solution.data ||
                  !featuredSolution.solution.data?.attributes
                )
                  return allSolutions;

                const {
                  name,
                  slug: topLevelSlug,
                  icon,
                } = featuredSolution.solution.data?.attributes;
                const topLevelPath =
                  locale === "en"
                    ? Paths.Solutions
                    : getLocalizedPath(
                        Paths.Solutions,
                        (locale as SupportedLocales) ?? DEFAULT_LANGUAGE
                      );

                const topLevelItem = createNavigationItem(
                  name,
                  `${topLevelPath}/${topLevelSlug}`,
                  locale,
                  true
                );
                if (icon && icon.data)
                  topLevelItem.icon = icon.data.attributes.url;

                if (
                  featuredSolution.items &&
                  featuredSolution.items.data &&
                  featuredSolution.items.data.length > 0
                ) {
                  topLevelItem.children = featuredSolution.items.data.map(
                    (subItem) => {
                      const { name, slug } = subItem.attributes;
                      return createNavigationItem(
                        name,
                        `${topLevelPath}/${topLevelSlug}/${slug}`,
                        locale,
                        true
                      );
                    }
                  );
                }
                return [...allSolutions, topLevelItem];
              },
              [] as INavigationItem[]
            );
        }
      }
      return navigationClone;
    }
  }, [navigationBarData, getBase, locale]);

  return {
    navigationMenu,
    ctaText: navigationBarData?.cta_text,
  };
}
