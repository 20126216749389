import { Block } from "@components/Block";
import { Markdown } from "@components/Markdown";

interface IMarkdownBlocksProps {
  markdown: string;
}

export default function MarkdownBlock({ markdown }: IMarkdownBlocksProps) {
  return (
    <Block>
      <Markdown content={markdown} />
    </Block>
  );
}
