import React from "react";
import { useRouter } from "next/router";
import { Button } from "components";
import { Paths } from "lib/routing";
import { APITypes } from "@lib/api.types";
import { IWithChildren } from "@lib/interfaces";
import { SupportedLocales } from "@lib/localization";
import { getLocalizedPath } from "@lib/routing/routing.util";

interface ButtonWrapperProps extends IWithChildren {
  button: APITypes.Components.Button;
  size?: "small" | "large";
  icon?: string;
}

const ButtonWrapper = ({
  button,
  icon,
  size = "small",
}: ButtonWrapperProps) => {
  const { locale } = useRouter();

  const { action, color, external_link = "", text } = button;

  let href: string | undefined;
  let external: boolean = false;

  if (!href && action === "goto:external_link") {
    href = external_link;
    external = true;
  }

  if (!href && action === "goto:emergency_number") {
    href = "tel:+32474240240";
    external = false;
  }

  if (!href && action && action.includes("goto:")) {
    const lookup: Partial<Record<APITypes.Components.ButtonActions, Paths[]>> =
      {
        "goto:solutions": [Paths.Solutions],
        "goto:second_hand_inventory": [
          Paths.Solutions,
          Paths.SecondHandInventory,
        ],
        "goto:jobs": [Paths.Jobs],
        "goto:cases": [Paths.Cases],
        "goto:blogs": [Paths.Blogs],
        "goto:about_us": [Paths.AboutUs],
        "goto:contact": [Paths.Contact],
        "goto:home": [Paths.Home],
      };
    const path = lookup[action];
    if (path) {
      href = "";
      path.forEach((path) => {
        href += getLocalizedPath(path, locale as SupportedLocales);
      });
    }
  }

  if (!href && action != null) {
    switch (action) {
      case APITypes.Components.ButtonActions.ActionScrollToForm:
        href = "#form";
        break;
      case APITypes.Components.ButtonActions.ActionScrollDown:
      default:
        href = "#content";
    }
  }

  return (
    <Button
      size={size}
      color={color}
      href={href}
      icon={icon}
      external={external}
    >
      {text}
    </Button>
  );
};

export { ButtonWrapper };
