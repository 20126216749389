import { APITypes } from "@lib/api.types";
import API from "@lib/fetchData";
import { fetcher } from "@lib/fetcher";
import { SupportedLocales } from "@lib/localization";
import { useMemo } from "react";
import useSWR from "swr";

export default function useGetNavigationBar(locale: SupportedLocales) {
  const { data, error } = useSWR<
    APITypes.PopulatedObject<APITypes.Components.NavigationBar>,
    any
  >(new API(locale).fetchNavigationURL(), fetcher);

  if (error) console.error("Could not fetch navigationBar: ", error);

  const navigationBarData = useMemo(() => {
    if (data && data.data) {
      return data.data.attributes;
    }
    return null;
  }, [data]);

  return {
    navigationBarData,
  };
}
