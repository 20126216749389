import { Listbox } from "@headlessui/react";
import { Svg } from "@components/Svg";
import { useField } from "formik";
import { TAutoForm } from "./AutoForm.types";

interface AutoFormSelectFieldProps
  extends Pick<
    TAutoForm.IInputProps,
    | "name"
    | "label"
    | "required"
    | "options"
    | "error"
    | "onSelect"
    | "translate"
  > {}

const AutoFormSelectField = ({
  name,
  label,
  options,
  required = false,
  error,
  onSelect,
}: AutoFormSelectFieldProps) => {
  const [input, , helperFunctions] = useField({
    name,
  });

  const { value } = input;
  const { setValue } = helperFunctions;

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div>
      <div>
        {label}
        {required && <span className="text-brand-green">*</span>}
      </div>
      <div className="mt-2">
        <Listbox
          className="relative"
          value={value}
          onChange={(value) => {
            if (onSelect != null) {
              onSelect(value);
            }
            setValue(value);
          }}
          as="div"
        >
          <Listbox.Button
            className="
              flex items-center justify-between
              w-full h-14 px-4
              bg-brand-gray dark:bg-opacity-30
            "
          >
            <span>{selectedOption ? selectedOption.label : ""}</span>
            <Svg className={`w-4 h-4`} icon="arrow-up" title="" />
          </Listbox.Button>
          <Listbox.Options
            className="
              absolute left-0 top-full
              z-50
              w-full
              bg-white | text-black
              shadow-xl
            "
          >
            {options.map((option) => (
              <Listbox.Option
                className="flex items-center h-12 px-4 hover:text-brand-green cursor-pointer"
                key={option.value}
                value={option.value}
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
        <div className={`text-brand-green font-medium text-sm`}>{error}</div>
      </div>
    </div>
  );
};

export { AutoFormSelectField };
