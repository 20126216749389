import { SupportedLocales } from "./localization";

export namespace APITypes {
  export type Populate<T> =
    | { [K in keyof Partial<T>]: { populate: any } | "*" }
    | "*";

  export interface StrapiQuery<T> {
    fields?: (keyof T)[];
    populate?: Populate<T>;
    filters?: { [x: string]: any };
    locale?: SupportedLocales;
    pagination?: { page: number; pageSize: number };
  }

  export interface StrapiResult<StrapiData> {
    data: StrapiData;
    meta: {
      pagination: {
        page: number;
        pageCount: number;
        pageSize: number;
        total: number;
      };
    };
  }

  export interface StrapiData<Attributes> {
    id: number;
    attributes: Attributes;
  }

  export interface DefaultAttributes<T> {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    //TODO: type locale?
    locale: string;
    localizations: PopulatedRelation<T>;
  }

  export interface PopulatedObject<T> {
    data?: StrapiData<T>;
  }

  export interface PopulatedRelation<T> {
    data?: StrapiData<T>[];
  }

  export interface Global {
    default_email_address: string;
    default_phone_number: string;
    location: PopulatedObject<APITypes.Collections.Location>;
    socials: Components.Social[];
  }

  export namespace Pages {
    interface Page {
      Hero: Components.Hero;
      seo?: Components.SEO;
    }
    export interface Home extends DefaultAttributes<Home>, Page {
      WhyGENS?: Components.WhyGENS;
      Button?: Components.Button;
      Contact?: Components.Contact;
    }

    export interface Blogs extends DefaultAttributes<Blogs>, Page {}
    export interface Jobs extends DefaultAttributes<Jobs>, Page {
      FeaturedJobs: {
        id: number;
        jobs: PopulatedRelation<Collections.Job>;
      };
    }
    export interface Solutions extends DefaultAttributes<Solutions>, Page {
      Contact: Components.Contact;
    }

    export interface Cases extends DefaultAttributes<Cases>, Page {
      FeaturedCases: {
        id: number;
        cases: PopulatedRelation<Collections.Case>;
      };
      Contact: Components.Contact;
    }

    export interface About extends DefaultAttributes<About>, Page {
      InformativeCompanyContent: Components.InformativeCompanyContent[];
      FeaturedTeamMembers: Components.FeaturedTeamMembers;
      FeaturedLocations: Components.FeaturedLocations;
      Contact: Components.Contact;
    }

    export interface Contact extends DefaultAttributes<Contact>, Page {
      InfoCards: Components.InfoCard[];
      FeaturedLocations: Components.FeaturedLocations;
    }

    export interface Policy extends DefaultAttributes<Policy>, Page {
      title: string;
      page_content_blocks: Components.ContentBlocks[];
      Contact: Components.Contact;
    }
  }

  export namespace Collections {
    export interface Blog extends DefaultAttributes<Blog> {
      Hero: Components.Hero;
      author?: string | null;
      slug: string;
      tags: any; // TODO: model relation
      body: string;
      seo?: Components.SEO;
      page_content_blocks: Components.ContentBlocks[];
    }

    export interface Case extends DefaultAttributes<Case> {
      name: string;
      short_description?: string;
      body: string;
      seo?: Components.SEO;
      slug: string;
      media?: PopulatedRelation<Components.Media>;
      page_content_blocks: Components.ContentBlocks[];
    }

    export interface Solution extends DefaultAttributes<Solution> {
      name: string;
      hero_image?: PopulatedObject<Components.Media>;
      icon: PopulatedObject<Components.Media>;
      description: string; //?
      display_order: number;
      illustration: PopulatedObject<Components.Media>;
      items?: PopulatedRelation<Item>;
      slug: string;
      button_text?: string;
      button_link?: string;
      type: Components.SolutionTypes;
      has_detail_page: boolean;
      seo: Components.SEO;
    }

    export interface Item extends DefaultAttributes<Item> {
      name: string;
      full_description: string;
      short_description: string;
      icon: PopulatedObject<Components.Media>;
      solution: PopulatedObject<Solution>;
      media: PopulatedRelation<Components.Media>;
      seo: Components.SEO;
      slug: string;
      page_content_blocks: Components.ContentBlocks[];
      stock: number;
    }

    export interface Job extends DefaultAttributes<Job> {
      Hero: Components.Hero;
      startdate: string;
      apply_to_email: string;
      location: PopulatedObject<Location>;
      tags: PopulatedRelation<Tag>;
      department: PopulatedObject<Department>;
      seo: Components.SEO;
      slug: string;
      description: string;
      page_content_blocks: Components.ContentBlocks[];
    }

    export interface TeamMember extends DefaultAttributes<TeamMember> {
      fullname: string;
      image?: PopulatedObject<Components.Media>;
    }
    export interface Location extends DefaultAttributes<Location> {
      name: string;
      address: string;
      countries?: string;
      email?: string;
      phone_number?: string;
    }

    export interface MapLocation extends DefaultAttributes<MapLocation> {
      title: string;
      location: string;
      contact: string;
      description: string;
      case: PopulatedObject<Case>;
      x: number;
      y: number;
    }
    export interface Tag extends DefaultAttributes<Tag> {}
    export interface Department extends DefaultAttributes<Department> {
      name: string;
    }
  }

  export namespace Components {
    export type ButtonColors = "green" | "gray";
    export enum ButtonActions {
      GotoHome = "goto:home",
      GotoContact = "goto:contact",
      GotoSolutions = "goto:solutions",
      GotoSecondHandInventory = "goto:second_hand_inventory",
      GotoCases = "goto:cases",
      GotoAboutUs = "goto:about_us",
      GotoJobs = "goto:jobs",
      GotoBlogs = "goto:blogs",
      GotoEmergencyNumber = "goto:emergency_number",
      GotoExternalLink = "goto:external_link",
      ActionStartChat = "action:start_chat",
      ActionScrollDown = "action:scroll_down",
      ActionScrollToForm = "action:scroll_to_form",
    }

    export enum SolutionTypes {
      ProductList = "product-list",
      ItemCards = "item-cards",
    }
    export interface NavigationBar {
      solutions: Array<{
        id: number;
        ShowRequestQuoteButton: boolean;
        items: PopulatedRelation<APITypes.Collections.Item>;
        solution: PopulatedObject<APITypes.Collections.Solution>;
      }>;
      cta_text: string;
      request_quote_text: string;
    }
    export interface Hero {
      id: number;
      title: string;
      text?: string;
      background_image?: PopulatedObject<Media> | PopulatedRelation<Media>;
      button?: Button;
    }

    export interface SEO {
      meta_title?: string;
      meta_description?: string;
      keywords?: string;
      meta_robots?: string;
      structured_data?: Record<string, any>;
      meta_viewport?: string;
      canonical_url?: string;
      meta_image?: PopulatedObject<Media>;
      meta_social?: Array<{
        social_network?: "Twitter" | "Facebook" | "Instagram" | "Linkedin";
        title?: string;
        description?: string;
        image?: Media;
      }>;
    }

    export interface Media {
      name: string;
      alternativeText: string;
      caption: string;
      width: number;
      height: number;
      formats: any[]; //TODO
      hash: string;
      ext: string;
      mime: string;
      size: number;
      url: string;
      previewUrl?: null | string;
      provider: string;
      provider_metadata: string | null;
      createdAt: string;
      updatedAt: string;
    }

    export interface WhyGENS {
      title: string;
      text?: string;
      content_blocks?: ContentBlock[];
      Button?: Button;
    }

    export interface ContentBlock {
      id: number;
      title: string;
      icon: PopulatedObject<Media>;
    }

    export interface InformativeCompanyContent {
      title: string;
      info: string;
      image: PopulatedObject<Media>;
    }

    export interface Button {
      text: string;
      color: ButtonColors;
      action: ButtonActions;
      external_link?: string;
    }

    export interface FeaturedTeamMembers {
      team_members: PopulatedRelation<Collections.TeamMember>;
    }
    export interface FeaturedLocations {
      title?: string;
      locations: PopulatedRelation<Collections.Location>;
    }

    export interface Contact {
      title: string;
      text?: string;
      left_button?: Button;
      right_button?: Button;
    }

    export interface InfoCard {
      id: number;
      title: string;
      text: string;
      icon: PopulatedObject<Media>;
      button: Button;
    }

    export interface Social {
      title: string;
      icon: string;
      link: string;
    }

    export type ContentBlocks =
      | ContentBlocks.Button
      | ContentBlocks.MultipleButtons
      | ContentBlocks.MarkdownBlock
      | ContentBlocks.ImageWithText;
    export namespace ContentBlocks {
      export enum Types {
        MarkdownBlock = "content-blocks.markdown-block",
        ImageWithTextBlock = "content-blocks.image-with-text",
        Button = "content-blocks.button",
        MultipleButtons = "content-blocks.multiple-buttons",
      }
      interface BaseProps {
        id: number;
        __component: Types;
      }

      export interface Button extends BaseProps {
        text: string;
        color: ButtonColors;
        link: string;
        external_link?: string;
      }
      export interface MarkdownBlock extends BaseProps {
        markdown: string;
      }

      export interface ImageWithText extends BaseProps {
        title: string;
        text: string;
        image: Media;
      }
      export interface MultipleButtons extends BaseProps {
        buttons: Array<Button>;
      }
    }
  }
}
