import { APITypes } from "@lib/api.types";
import { DEFAULT_LANGUAGE, SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { Button } from "..";
import { Marker } from "./DotMap.types";

interface DotMapProps {
  mapLocations: APITypes.StrapiData<APITypes.Collections.MapLocation>[];
  translate: TFunction;
}

const MAX_TEXT_LENGTH = 120;

const DotMap = ({ mapLocations, translate }: DotMapProps) => {
  const { locale } = useRouter();
  const [activeMarker, setActiveMarker] = useState<Marker.Marker | null>(null);

  const handleMarkerClick = (marker: Marker.Marker) => {
    if (activeMarker?.id === marker.id) {
      setActiveMarker(null);
    } else {
      setActiveMarker(marker);
    }
  };

  const isActive = (marker: any) => marker.id === activeMarker?.id;

  const getPanelPosition = (marker: any) => {
    if (marker.position.y > 0.5) {
      return {
        left: `${marker.position.x}%`,
      };
    }

    return {
      right: `${100 - marker.position.x}%`,
    };
  };

  const markers: Marker.Marker[] = mapLocations.reduce(
    (allCases, { attributes, id }) => {
      const caseData = attributes.case.data;
      if (!caseData) return allCases;
      const caseObject = {
        id,
        contact: attributes.contact,
        location: attributes.location,
        position: {
          x: attributes.x,
          y: attributes.y,
        },
        title: attributes.title,
        content: attributes.description,
        button: {
          text: translate("map.marker.buttonText"),
          url: `${getLocalizedPath(
            Paths.Cases,
            (locale ?? DEFAULT_LANGUAGE) as SupportedLocales
          )}/${attributes.case.data?.attributes.slug}`,
        },
      };
      return [...allCases, caseObject];
    },
    [] as Marker.Marker[]
  );

  const Content = () => (
    <div className="relative">
      {/* eslint-disable-next-line jsx-a11y/alt-text,  @next/next/no-img-element*/}
      <img
        alt={translate("map.imageAlt")}
        className="w-auto h-[510px] max-w-none lg:w-full lg:h-auto"
        src="/dot-map.svg"
        width="1071"
        height="510"
      />
      {markers.map((marker) => (
        <div
          className="absolute | flex | -translate-x-1/2 -translate-y-1/2"
          style={{
            left: `${marker.position.x}%`,
            top: `${marker.position.y}%`,
          }}
          key={marker.id}
        >
          <button
            name="location on map"
            className={`relative z-10 | w-4 h-4 | ${
              isActive(marker) ? "bg-brand-green" : "bg-brand-blue"
            } | rounded-full | transition-colors`}
            onClick={() => {
              handleMarkerClick(marker);
            }}
          />
          {isActive(marker) && (
            <div
              className={`absolute inset-0 | w-4 h-4 | bg-brand-green | rounded-full | transition-colors | animate-ping`}
            />
          )}
          {!isActive(marker) && (
            <div
              className={`absolute inset-0 | w-4 h-4 | bg-brand-blue | rounded-full | transition-colors | animate-ping`}
            />
          )}
        </div>
      ))}
      {activeMarker && (
        <div
          className="absolute top-1/2 | grid gap-4 | w-[300px] lg:w-[360px] | mx-6 p-8 | bg-white bg-opacity-90 | shadow-xl | -translate-y-1/2"
          style={getPanelPosition(activeMarker)}
        >
          <h3 className="headline-3">{activeMarker.title}</h3>
          <div>
            Location: {activeMarker.location}
            <br />
            Contact: {activeMarker.contact}
            <br />
          </div>
          <div>
            {activeMarker.content.length > MAX_TEXT_LENGTH
              ? `${activeMarker.content.substring(0, MAX_TEXT_LENGTH - 3)}...`
              : activeMarker.content}
          </div>
          <div>
            <Button
              href={activeMarker.button.url}
              color="green"
              size="small"
              icon="arrow-right"
            >
              {activeMarker.button.text}
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="w-screen lg:w-full | -mx-12 lg:mx-0">
      <div className="lg:hidden">
        <TransformWrapper
          minScale={1}
          maxScale={1}
          minPositionY={0}
          maxPositionY={0}
          centerOnInit
          centerZoomedOut
          panning={{
            velocityDisabled: true,
          }}
        >
          <TransformComponent
            wrapperStyle={{
              width: "100%",
              overflowY: "auto",
            }}
          >
            <div className="px-64">
              <Content />
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
      <div className="hidden lg:block">
        <Content />
      </div>
    </div>
  );
};

export { DotMap };
