import { APITypes } from "@lib/api.types";

import { Button, ButtonWrapper } from "components";

interface ContactProps {
  contact: APITypes.Components.Contact;
}

function Contact({ contact }: ContactProps) {
  return (
    <div className="relative | px-6 py-12 sm:px-10 md:p-14 xl:p-24 | sm:text-center | bg-brand-green text-white | breakout-block-cutout">
      <svg
        className="absolute left-1/2 top-0 bottom-0 md:left-0 | w-auto h-full | -translate-x-1/2 md:translate-x-0"
        viewBox="0 0 653 501"
      >
        <path
          fill="#61BB9E"
          fillOpacity=".6"
          d="m-225 964 415.35-656.8 261.9 136.2L653-484 237.65 172.78l-261.9-136.2L-225 964Z"
        />
      </svg>
      <div className="relative z-20">
        <h2 className="headline-2">{contact.title}</h2>
        {contact.text ? (
          <p className="sm:max-w-md | mt-2 mx-auto sm:mt-4 | lg:text-xl xl:text-2xl">
            {contact.text}
          </p>
        ) : null}
        <div className="grid gap-2 md:grid-cols-2 lg:gap-14 | max-w-[1024px] mx-auto | mt-6 sm:mt-12 xl:mt-24">
          {contact.left_button ? (
            <ButtonWrapper
              button={contact.left_button}
              size="large"
              icon="arrow-right"
            >
              {contact.left_button?.text}
            </ButtonWrapper>
          ) : null}

          {contact.right_button ? (
            <ButtonWrapper
              button={contact.right_button}
              size="large"
              icon="arrow-right"
            >
              {contact.left_button?.text}
            </ButtonWrapper>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export { Contact };
