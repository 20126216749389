import {
  IWithChildren,
} from "@lib/interfaces";

interface TeaserGridProps extends IWithChildren {
  columns?: number,
  gap?: 'sm' | 'lg',
}

const TeaserGrid = ({ columns = 2, gap = 'sm', children } : TeaserGridProps) => {
  const classes = [
    'grid',
    gap == 'sm' && 'gap-6 md:gap-8',
    gap == 'lg' && 'gap-6 md:gap-8 lg:gap-16',
    columns === 2 && 'md:grid-cols-2',
    columns === 3 && 'md:grid-cols-3',
  ].filter(Boolean).join(' ');

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export { TeaserGrid };
