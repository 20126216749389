import { useField } from "formik";
import { TAutoForm } from "./AutoForm.types";

interface AutoFormTextareaFieldProps
  extends Omit<TAutoForm.IInputProps, "options"> {
  type?: "text" | "email";
}

const AutoFormTextareaField = ({
  name,
  label,
  placeholder = "",
  required = false,
  error,
}: AutoFormTextareaFieldProps) => {
  const [input] = useField({
    name,
  });

  return (
    <label>
      <div>
        {label}
        {required && <span className="text-brand-green">*</span>}
      </div>
      <div className="mt-2">
        <textarea
          className={`
            w-full p-4
            bg-brand-gray dark:bg-opacity-30
            placeholder:text-black placeholder:text-opacity-50 dark:placeholder:text-white dark:placeholder:text-opacity-50
            focus:outline-none
            ${
              error &&
              "border-2 border-brand-green bg-brand-green bg-opacity-15"
            }`}
          {...input}
          placeholder={placeholder}
          rows={4}
        />
        <div className={`text-brand-green font-medium text-sm`}>{error}</div>
      </div>
    </label>
  );
};

export { AutoFormTextareaField };
