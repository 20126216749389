import {
    IWithChildren,
} from "@lib/interfaces";

export interface BlockProps extends IWithChildren{
    width?: 'sm' | 'md' | 'full' | 'breakout',
}

const Block = ({ width = 'md', children } : BlockProps) => {
    const classes = [
        'w-full',
        'mx-auto',
        width === 'sm' && 'max-w-[1032px]',
        width === 'md' && 'max-w-[1180px]',
        width === 'full' && 'max-w-[1360px]',
        width === 'breakout' && 'max-w-[1360px] w-screen | overflow-hidden | -mx-6 sm:-mx-10 md:w-full md:mx-auto',
    ].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export { Block };
