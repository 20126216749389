import { APITypes } from "@lib/api.types";
import { SupportedLocales } from "@lib/localization";
import {
  enStringToPathsMap,
  frStringToPathsMap,
  nlStringToPathsMap,
  Paths,
  pathToEnStringMap,
  pathToFrStringMap,
  pathToNlStringMap,
} from "./routing";

export const getPathFromString = (
  pathAsString: string,
  locale: string
): Paths | undefined => {
  let stringPath: Paths | null = null;

  const path = pathAsString.split("#")[0];

  switch (locale) {
    //TODO: add cases for other languages
    case "nl":
      stringPath = nlStringToPathsMap.get(path) ?? null;
      break;
    // case "fr":
    //   stringPath = frStringToPathsMap.get(path) ?? null;
    //   break;
    case "en":
      stringPath = enStringToPathsMap.get(path) ?? null;
      break;
    default:
      stringPath = null;
  }

  if (stringPath === null)
    throw new Error(
      `A translated string does not exist for string ${pathAsString} & locale ${locale} `
    );
  return stringPath;
};

export const StringToPathMap = new Map<string, Paths>([
  ["/solutions", Paths.Solutions],
  ["/jobs", Paths.Jobs],
  ["/cases", Paths.Cases],
  ["/blogs", Paths.Blogs],
  ["/about-us", Paths.AboutUs],
  ["/contact", Paths.Contact],
  ["/privacy-policy", Paths.PrivacyPolicy],
  ["/cookie-policy", Paths.CookiePolicy],
  ["/sitemap", Paths.Sitemap],
  ["/", Paths.Home],
]);

export const getLocalizedPath = (
  path: Paths,
  locale: SupportedLocales
): string => {
  let localizedPath: string | null = null;

  switch (locale) {
    case "nl":
      localizedPath = pathToNlStringMap.get(path) ?? null;
      break;
    // case "fr":
    //   localizedPath = pathToFrStringMap.get(path) ?? null;
    //   break;
    case "en":
      localizedPath = pathToEnStringMap.get(path) ?? null;
      break;
    default:
      localizedPath = pathToEnStringMap.get(path) ?? null; // used to be "null"
  }

  if (localizedPath === null)
    throw new Error(
      `A translated path does not exist for path ${path} & locale ${locale} `
    );
  return localizedPath;
};

export const getBlogItemUrl = (slug: string, locale: SupportedLocales) => {
  return `${getLocalizedPath(Paths.Blogs, locale)}/${slug}`;
};

type SlugType<T extends { slug: string }> = APITypes.StrapiData<
  APITypes.DefaultAttributes<T>
>;

export function getSlugFromLocalization<T extends { slug: string }>(
  object: SlugType<T>
): string | undefined {
  return object.attributes.localizations &&
    object.attributes.localizations.data &&
    object.attributes.localizations.data.length > 0
    ? object.attributes.localizations.data[0].attributes.slug
    : undefined;
}
