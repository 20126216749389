import { APITypes } from "@lib/api.types";
import MarkdownBlock from "./blocks/MarkdownBlock";
import MultipleButtonsBlock from "./blocks/MultipleButtonsBlock";
import SingleButtonBlock from "./blocks/SingleButtonBlock";
import TextWithImageBlock from "./blocks/TextWithImageBlock";

interface IContentBuilderProps {
  contentBlocks?: APITypes.Components.ContentBlocks[];
}

const ContentBuilder = ({ contentBlocks }: IContentBuilderProps) => {
  const getComponent = (
    component: APITypes.Components.ContentBlocks,
    i: number
  ) => {
    let c: APITypes.Components.ContentBlocks;

    switch (component.__component) {
      case APITypes.Components.ContentBlocks.Types.MarkdownBlock:
        c = component as APITypes.Components.ContentBlocks.MarkdownBlock;
        return <MarkdownBlock key={i} markdown={c.markdown} />;
      case APITypes.Components.ContentBlocks.Types.ImageWithTextBlock:
        c = component as APITypes.Components.ContentBlocks.ImageWithText;
        return <TextWithImageBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.MultipleButtons:
        c = component as APITypes.Components.ContentBlocks.MultipleButtons;
        return <MultipleButtonsBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.Button:
        c = component as APITypes.Components.ContentBlocks.Button;
        return <SingleButtonBlock key={i} block={c} />;
      default:
        throw new Error(`Unknown content block ${component.__component}`);
    }
  };

  return (
    <div className="grid gap-16">
      {contentBlocks ? contentBlocks.map((c, i) => getComponent(c, i)) : null}
    </div>
  );
};

export { ContentBuilder };
