import Head from "next/head";
import { APITypes } from "../../lib/api.types";
export interface IMeta extends APITypes.Components.SEO {}

export default function Meta({
  meta_title,
  meta_description,
  meta_image,
  meta_robots,
  meta_viewport,
  keywords,
}: IMeta) {
  return (
    <Head>
      <title>{meta_title}</title>
      <meta charSet="UTF-8" />
      <meta name="keywords" content={keywords} />
      <meta name="title" content={meta_title} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content={meta_robots ?? "all"} />
      <meta name="description" content={meta_description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={meta_title} />
      {meta_image?.data?.attributes && (
        <meta property="og:image" content={meta_image?.data.attributes.url} />
      )}
      <meta property="og:description" content={meta_description} />
    </Head>
  );
}