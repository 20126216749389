import { useRef, useEffect } from "react";
import useSWR from "swr";
import lottie from "lottie-web";

const Animation = ({
  src,
  width = 1120,
  height = 880,
}: {
  src: string;
  width?: number;
  height?: number;
}) => {
  const element = useRef<HTMLDivElement>(null);
  const {
    data,
    //TODO: this code is only valid when the image is on the strapi server instead of an external service
  } = useSWR(`${process.env.NEXT_PUBLIC_STRAPI_URL}${src}`, (url: string) =>
    fetch(url).then((r) => r.json())
  );

  useEffect(() => {
    if (element.current && data) {
      const animation = lottie.loadAnimation({
        container: element.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: data,
      });
      animation.play();

      return () => animation.destroy();
    }
  }, [element, data]);

  return (
    <div
      className="relative | w-full"
      style={{ paddingTop: `${(height / width) * 100}%` }}
    >
      <div className="absolute inset-0 | w-full h-full" ref={element} />
    </div>
  );
};

export { Animation };
