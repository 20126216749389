import { Listbox } from "@headlessui/react";
import {
  availableLanguages,
  DEFAULT_LANGUAGE,
  SupportedLocales,
} from "@lib/localization";
import { NEXT_LOCALE_COOKIE, Paths } from "@lib/routing";
import { getLocalizedPath, getPathFromString } from "@lib/routing/routing.util";
import { Svg } from "components";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import Cookies from "js-cookie";

const setNextLocaleCookieTo = (locale: SupportedLocales) => {
  const existingCookie = Cookies.get(NEXT_LOCALE_COOKIE);
  if (existingCookie) {
    console.log("existingCookie", existingCookie);
  }
  Cookies.set(NEXT_LOCALE_COOKIE, locale, { SameSite: "None", secure: true });
};

type LanguageSwitcherProps = {
  slug: string | undefined;
  isLanguageSwitcherOpen: boolean;
  setActiveSubmenuHandle: (string: string) => void;
  setIsLanguageSwitcherOpen: (isOpen: boolean) => void;
};

const LanguageSwitcher = ({
  slug,
  isLanguageSwitcherOpen,
  setActiveSubmenuHandle,
  setIsLanguageSwitcherOpen,
}: LanguageSwitcherProps) => {
  const router = useRouter();

  const activeLanguage = useMemo(() => {
    const l = availableLanguages.find(
      (language) => language.value === router.locale
    );
    if (!l) return DEFAULT_LANGUAGE;
    return l;
  }, [router]);

  const onSelectOtherLanguage = useCallback(
    (language: string) => {
      const l = availableLanguages.find((al) => al.value === language);
      if (l) {
        const { value: chosenLocale } = l;
        const pathParts = router.asPath.split("/");
        const pathString = pathParts[1];
        const subroutes = pathParts.slice(1, pathParts.length - 2);

        const p = subroutes.length === 0 ? pathString : subroutes[0];

        const path = getPathFromString(
          `/${p}`,
          router.locale as SupportedLocales
        );
        const localizedPath = getLocalizedPath(
          path ?? Paths.Home,
          chosenLocale
        );

        const updatedPath =
          slug != null ? `${localizedPath}/${slug}` : localizedPath;

        setNextLocaleCookieTo(chosenLocale);
        router.push(updatedPath, updatedPath, {
          locale: chosenLocale,
        });
      }
    },
    [slug, router]
  );

  return (
    <Listbox
      className="relative"
      value={router.locale}
      onChange={onSelectOtherLanguage}
      as="div"
    >
      {() => (
        <>
          <Listbox.Button
            className="flex items-center"
            onClick={() => {
              setIsLanguageSwitcherOpen(!isLanguageSwitcherOpen);
              setActiveSubmenuHandle("");
            }}
          >
            {activeLanguage && (
              <Svg
                className="w-5"
                icon={`flag-${activeLanguage.flag}`}
                title={activeLanguage.label}
                viewBox="0 0 640 480"
              />
            )}

            <Svg
              className={`w-4 h-4 | ml-2 | transition-transform ${
                isLanguageSwitcherOpen && "-scale-100"
              }`}
              icon="arrow-up"
              title=""
            />
          </Listbox.Button>
          <Listbox.Options className="absolute top-full -left-10 | grid gap-2 | mt-12 | px-10 py-6 | bg-brand-side-blue text-white">
            <h3 className="mb-2 | font-bold text-2xl">Language</h3>
            {availableLanguages.map((language) => (
              <Listbox.Option
                className="flex items-center | gap-2 | hover:text-brand-green | cursor-pointer"
                value={language.value}
                key={language.value}
              >
                <Svg
                  className="w-5"
                  icon={`flag-${language.flag}`}
                  title={language.label}
                  viewBox="0 0 640 480"
                />
                <span
                  className={
                    language.value === activeLanguage?.value ? "font-bold" : ""
                  }
                >
                  {language.label}
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );

  //  return <button className="flex items-center gap-2"></button>;
};

export { LanguageSwitcher };
