import { useCreateFooterNavigationMenu } from "@components/Navigation/useCreateNavigationMenu";
import { IWithChildren } from "@lib/interfaces";
import { DEFAULT_LANGUAGE, SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { Container, Svg } from "components";
import { TFunction } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import useGetGlobals from "./useGetGlobals";
import { SanitizeString } from "@lib/SanitizeString";

const generateMenuItem = (title: string, path: string) => ({
  title,
  url: path,
});

export interface FooterProps extends IWithChildren {
  translate: TFunction;
}

export default function Footer({ translate: t, children }: FooterProps) {
  const { locale } = useRouter();
  const l = (locale ?? DEFAULT_LANGUAGE) as SupportedLocales;
  const { globalData } = useGetGlobals(l);
  const { navigationMenu } = useCreateFooterNavigationMenu(l, globalData);

  const closure = [
    generateMenuItem(
      t("pages.privacyPolicy"),
      getLocalizedPath(Paths.PrivacyPolicy, l)
    ),
    generateMenuItem(
      t("pages.cookiePolicy"),
      getLocalizedPath(Paths.CookiePolicy, l)
    ),
    generateMenuItem(
      t("pages.manageCookies"),
      getLocalizedPath(Paths.ManageCookies, l)
    ),
  ];

  return (
    <footer className="bg-[#1E2636] text-white footer-container">
      <Container padding="x">
        <div className="max-w-[1360px] | mx-auto py-6 sm:py-12 md:py-20 lg:py-24">
          <div className="grid lg:grid-cols-4 gap-6 justify-items-between">
            {globalData != null && (
              <div className="grid col-span-1 gap-2 items-start">
                <div
                  style={{
                    width: "260px",
                    marginLeft: "-10px",
                    marginTop: "-10px",
                  }}
                >
                  <Image
                    src="/gens-logo-white.svg"
                    alt="logo"
                    width={260}
                    height={70}
                  />
                </div>
                <div className="flex gap-4">
                  {globalData.socials.map((social) => (
                    <a
                      className="hover:text-brand-green | transition-colors"
                      href={social.link}
                      key={social.icon}
                    >
                      <Svg
                        className="w-6 h-6"
                        icon={social.icon}
                        title={social.title}
                      />
                    </a>
                  ))}
                </div>

                <div>
                  {`${t("pages.visit")} `}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-brand-green | transition-colors underline"
                    href={`https://www.jecoenergies.com/`}
                  >
                    jecoenergies.com
                  </a>
                </div>
              </div>
            )}
            <div className="grid gap-4 lg:col-span-3 justify-items-between">
              <div className="grid gap-6 lg:justify-items-end xl:gap-20 sm:grid-cols-2 lg:col-span-3 xl:grid-cols-4">
                {navigationMenu &&
                  navigationMenu.map((item, i) => (
                    <div
                      className="grid gap-4 content-start lg:w-[200px] h-fit"
                      key={i}
                    >
                      <h3 className="headline-3">{item.title}</h3>
                      {item.children.map((child, i) => (
                        <div key={i} className="flex items-center h-fit">
                          <Link
                            href={child.url}
                            className={`${
                              child.url === "" && "pointer-events-none"
                            }`}
                          >
                            <a
                              className={`flex | h-6 | hover:text-brand-green transition-colors h-fit ${
                                child.url === "" && "pointer-events-none"
                              }`}
                            >
                              <SanitizeString content={child.title} />
                            </a>
                          </Link>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container className="bg-[#333B4C]" padding="x">
        <div className="max-w-[1360px] | mx-auto">
          <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-12 | py-6">
            <div className="sm:order-2 xl:order-1 xl:col-span-3 | flex items-center | h-12">
              &copy; {new Date().getFullYear()} - GENS
            </div>
            <div className="sm:order-1 sm:col-span-2 xl:order-2 xl:col-span-6">
              <div className="grid gap-2 sm:flex sm:gap-6 xl:justify-center xl:items-center | xl:h-12">
                {closure.map((link) => (
                  <Link href={link.url} key={link.url}>
                    <a className="">{link.title}</a>
                  </Link>
                ))}
              </div>
            </div>
            <div className="sm:order-3 xl:order-3 xl:col-span-3 | flex items-center sm:justify-end | h-12 | font-bold sm:font-normal">
              <div>
                Website by <a href="https://www.pandapanda.be">PandaPanda 🐼</a>
              </div>
            </div>
          </div>
        </div>
        <div className="h-12 sm:hidden"></div>
      </Container>
    </footer>
  );
}
