import DOMPurify from "isomorphic-dompurify";

interface SanitizeStringProps {
  content: string;
}

export const SanitizeString = ({ content }: SanitizeStringProps) => {
  const cleanText = DOMPurify.sanitize(content);

  const cleanerText = cleanText
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replaceAll(
      "<a",
      `<a target="_blank" class="font-semibold text-brand-green underline"`
    );

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: cleanerText,
      }}
    />
  );
};
