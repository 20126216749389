import { Block, ButtonWrapper } from "components";

const MultipleButtonsBlock = ({ block }: any) => {
  return (
    <Block>
      <div className="grid gap-2 sm:grid-cols-2 sm:gap-6 md:gap-8 lg:gap-16 | max-w-[1020px] | mx-auto">
        {block &&
          block.buttons &&
          block.buttons.map((button: any) => (
            <ButtonWrapper button={button} size="large" key={button.id} />
          ))}
      </div>
    </Block>
  );
};

export default MultipleButtonsBlock;
