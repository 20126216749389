import { useCreateNavigationMenu } from "./useCreateNavigationMenu";
import { DEFAULT_LANGUAGE, SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { Button, DarkModeSwitch, LanguageSwitcher, Svg } from "components";
import { TFunction } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

type DesktopNavigationProps = {
  activateSticky?: boolean;
  translate: TFunction;
  slug: string | undefined;
  isDarkMode: boolean;
  setIsDarkMode: (isDarkMode: boolean) => void;
};

const DesktopNavigation = ({
  activateSticky,
  translate,
  slug,
  isDarkMode,
  setIsDarkMode,
}: DesktopNavigationProps) => {
  const router = useRouter();
  const { navigationMenu, ctaText } = useCreateNavigationMenu(
    (router.locale ?? DEFAULT_LANGUAGE) as SupportedLocales
  );

  const [isSticky, setIsSticky] = useState(false);
  const [isLanguageSwitcherOpen, setIsLanguageSwitcherOpen] = useState(false);

  const [activeSubmenuHandle, setActiveSubmenuHandle] = useState("");
  const activeSubmenu = navigationMenu?.find(
    (item) => item.title === activeSubmenuHandle
  );

  const handleDropdownClick = (item: string) => {
    if (activeSubmenuHandle === item) {
      setActiveSubmenuHandle("");
      setIsLanguageSwitcherOpen(false);
    } else {
      setIsLanguageSwitcherOpen(false);
      setActiveSubmenuHandle(item);
    }
  };

  function handleCloseMenu() {
    setActiveSubmenuHandle("");
  }

  const checkActiveLink = (url: string) => {
    return url && router.asPath.includes(url);
  };

  useEffect(() => {
    if (window && activateSticky) {
      const check = () => {
        if (window.scrollY > window.innerHeight) {
          if (!isSticky) {
            setIsSticky(true);
          }
        } else {
          setIsSticky(false);
        }

        window.requestAnimationFrame(check);
      };

      window.requestAnimationFrame(check);
    }
  });

  let containerClasses = [
    "z-20",
    "hidden xl:block",
    "w-full",
    "bg-white dark:bg-brand-blue",
  ];

  if (activateSticky) {
    containerClasses.push("fixed left-0 top-0 | transition-transform");

    if (!isSticky) {
      containerClasses.push("-translate-y-full");
    } else {
      containerClasses.push("shadow-xl");
    }
  } else {
    containerClasses.push("relative");
  }

  return (
    <div className={containerClasses.join(" | ")}>
      <div className="px-10">
        <div
          className={`relative flex items-center justify-between gap-8 | ${
            activateSticky ? "h-20" : "h-[106px]"
          } max-w-[1360px] | mx-auto`}
        >
          <a className="" href={`/${router.locale}`}>
            {isDarkMode ? (
              <Image
                src="/gens-logo-white.svg"
                alt="logo"
                width={200}
                height={60}
              />
            ) : (
              <Image
                src="/gens-logo-blue.svg"
                alt="logo"
                width={200}
                height={60}
              />
            )}
          </a>
          <ul className="flex gap-6 | ml-4 mr-4">
            {navigationMenu?.map((item) => (
              <li key={item.title}>
                {item.children.length ? (
                  <div className="relative">
                    <button
                      className={`
                        flex items-center
                        h-[106px]
                        ${
                          activeSubmenuHandle === item.title &&
                          "text-brand-green font-medium"
                        }
                        ${checkActiveLink(item.url) && "text-brand-green"}
                        hover:text-brand-green
                        transition-colors
                      `}
                      onClick={() => {
                        handleDropdownClick(item.title);
                      }}
                    >
                      {item.title}
                      <Svg
                        className={`w-4 h-4 | mt-1 ml-2 | transition-transform ${
                          activeSubmenuHandle === item.title && "-scale-100"
                        }`}
                        icon="arrow-up"
                        title=""
                      />
                    </button>
                    {activeSubmenu &&
                      activeSubmenu.dropdown === "simple" &&
                      activeSubmenu.title === item.title && (
                        <div
                          className="
                          absolute left-1/2 top-full
                          grid gap-4
                          mt-2 px-12 py-6
                          bg-brand-side-blue text-white
                          -translate-x-1/2
                        "
                        >
                          {activeSubmenu.children.map((item) => {
                            const locale = (router.locale ??
                              DEFAULT_LANGUAGE) as SupportedLocales;

                            const localizedPath = item.url
                              ? getLocalizedPath(item.url as Paths, locale)
                              : "";
                            return (
                              <>
                                <Link href={localizedPath} key={item.url}>
                                  <a
                                    className="hover:text-brand-green | transition-colors"
                                    onClick={handleCloseMenu}
                                  >
                                    {item.title}
                                  </a>
                                </Link>
                              </>
                            );
                          })}
                        </div>
                      )}
                  </div>
                ) : (
                  <Link href={item.url}>
                    <a
                      className={`
                        flex items-center | h-[106px] | hover:text-brand-green | transition-colors | whitespace-nowrap
                        ${
                          checkActiveLink(item.url) &&
                          "text-brand-green font-medium"
                        }
                      `}
                    >
                      {item.title}
                    </a>
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <div className="flex items-center gap-6 | ml-auto mr-0">
            <DarkModeSwitch
              className="hover:text-brand-green"
              isDarkMode={isDarkMode}
              setIsDarkMode={setIsDarkMode}
            />
            <LanguageSwitcher
              slug={slug}
              isLanguageSwitcherOpen={isLanguageSwitcherOpen}
              setActiveSubmenuHandle={setActiveSubmenuHandle}
              setIsLanguageSwitcherOpen={setIsLanguageSwitcherOpen}
            />
            {activateSticky && (
              <a
                className="
                  flex items-center gap-8
                  h-12
                  px-8
                  leading-6
                  bg-brand-green text-white hover:bg-brand-night dark:hover:bg-brand-side-blue
                  transition-colors
              "
                href="tel:+32474240240"
              >
                <span>
                  <strong className="font-bold">
                    <>{translate("call")} +32(0)474 240 240</>
                  </strong>
                </span>
                <span className="flex items-center gap-2 | font-bold">
                  <Svg className="w-6 h-6" icon="phone" title="Phone" />
                  24/7
                </span>
              </a>
            )}
            {!activateSticky && (
              <a
                className="
                  flex items-center gap-4
                  h-[72px]
                  px-8
                  leading-6
                  bg-brand-green text-white hover:bg-brand-night dark:hover:bg-brand-side-blue
                  transition-colors
              "
                href="tel:+32474240240"
              >
                <span>
                  {ctaText}
                  <br />
                  <strong className="font-bold | whitespace-nowrap">
                    {`${translate("call")} +32(0)474 240 240`}
                  </strong>
                </span>
                <span className="flex items-center gap-2 | font-bold">
                  <Svg className="w-6 h-6" icon="phone" title="Phone" />
                  24/7
                </span>
              </a>
            )}
          </div>
          {activeSubmenu && activeSubmenu.dropdown === "extended" && (
            <div
              className="
                absolute left-0 top-full right-0
                m-2
                p-12 pt-6
                bg-brand-side-blue text-white
              "
            >
              <div className="grid grid-cols-3 gap-6">
                {activeSubmenu.children.map((item) => {
                  return (
                    <div key={item.title}>
                      <div className="flex items-center gap-2 | headline-3">
                        {item.icon && (
                          <Image
                            className="w-8 h-8"
                            src={`${process.env.NEXT_PUBLIC_STRAPI_URL}/${item.icon}`}
                            width={32}
                            height={32}
                            alt=""
                          />
                        )}
                        <Link href={item.url}>
                          <a onClick={handleCloseMenu}>{item.title}</a>
                        </Link>
                      </div>
                      <div className="grid gap-2 | ml-10 mt-4">
                        {item.children.map((subItem, index) => (
                          <Link href={subItem.url} key={index}>
                            <a
                              className="hover:text-brand-green"
                              onClick={handleCloseMenu}
                            >
                              {subItem.title}
                            </a>
                          </Link>
                        ))}
                      </div>
                      {item.button && item.button.url && (
                        <div className="ml-10 mt-6">
                          <Button
                            href={item.button.url ?? ""}
                            size="small"
                            color="green"
                            icon="arrow-right"
                          >
                            {item.button.text}
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DesktopNavigation };
