export const NEXT_LOCALE_COOKIE = "NEXT_LOCALE";

import {
  FR_ABOUT_US,
  FR_BLOGS,
  FR_CASES,
  FR_CONTACT,
  FR_COOKIE_POLICY,
  FR_JOBS,
  FR_MANAGE_COOKIES,
  FR_MORE,
  FR_SECOND_HAND_INVENTORY,
  FR_PRIVACY_POLICY,
  FR_SOLUTIONS,
  NL_ABOUT_US,
  NL_BLOGS,
  NL_CASES,
  NL_CONTACT,
  NL_COOKIE_POLICY,
  NL_JOBS,
  NL_MANAGE_COOKIES,
  NL_MORE,
  NL_SECOND_HAND_INVENTORY,
  NL_PRIVACY_POLICY,
  NL_SOLUTIONS,
  NL_SITEMAP,
  FR_SITEMAP,
  FR_EMERGENCY,
  NL_EMERGENCY,
} from "./rewrites";

export enum Paths {
  Solutions = "/solutions",
  SecondHandInventory = "/second-hand-inventory",
  Jobs = "/jobs",
  Cases = "/cases",
  Blogs = "/blogs",
  AboutUs = "/about-us",
  Contact = "/contact",
  PrivacyPolicy = "/privacy-policy",
  CookiePolicy = "/cookie-policy",
  ManageCookies = "/manage-cookies",
  More = "/more",
  Sitemap = "/sitemap",
  Emergency = "/emergency",
  Home = "/",
}
//Path to translation
export const pathToNlStringMap = new Map<Paths, string>([
  [Paths.Solutions, `/${NL_SOLUTIONS}`],
  [Paths.SecondHandInventory, `/${NL_SECOND_HAND_INVENTORY}`],
  [Paths.Jobs, `/${NL_JOBS}`],
  [Paths.Cases, `/${NL_CASES}`],
  [Paths.Blogs, `/${NL_BLOGS}`],
  [Paths.AboutUs, `/${NL_ABOUT_US}`],
  [Paths.Contact, `/${NL_CONTACT}`],
  [Paths.PrivacyPolicy, `/${NL_PRIVACY_POLICY}`],
  [Paths.CookiePolicy, `/${NL_COOKIE_POLICY}`],
  [Paths.ManageCookies, `/${NL_MANAGE_COOKIES}`],
  [Paths.Sitemap, `/${NL_SITEMAP}`],
  [Paths.Emergency, `/${NL_EMERGENCY}`],
  [Paths.More, `/${NL_MORE}`],
  [Paths.Home, "/"],
]);

export const pathToFrStringMap = new Map<Paths, string>([
  [Paths.Solutions, `/${FR_SOLUTIONS}`],
  [Paths.SecondHandInventory, `/${FR_SECOND_HAND_INVENTORY}`],
  [Paths.Jobs, `/${FR_JOBS}`],
  [Paths.Cases, `/${FR_CASES}`],
  [Paths.Blogs, `/${FR_BLOGS}`],
  [Paths.AboutUs, `/${FR_ABOUT_US}`],
  [Paths.Contact, `/${FR_CONTACT}`],
  [Paths.PrivacyPolicy, `/${FR_PRIVACY_POLICY}`],
  [Paths.CookiePolicy, `/${FR_COOKIE_POLICY}`],
  [Paths.ManageCookies, `/${FR_MANAGE_COOKIES}`],
  [Paths.Sitemap, `${FR_SITEMAP}`],
  [Paths.Emergency, `${FR_EMERGENCY}`],
  [Paths.More, `${FR_MORE}`],
  [Paths.Home, "/"],
]);

export const pathToEnStringMap = new Map<Paths, string>([
  [Paths.Solutions, "/solutions"],
  [Paths.SecondHandInventory, "/second-hand-inventory"],
  [Paths.Jobs, "/jobs"],
  [Paths.Cases, "/cases"],
  [Paths.Blogs, "/blogs"],
  [Paths.AboutUs, "/about-us"],
  [Paths.Contact, "/contact"],
  [Paths.PrivacyPolicy, "/privacy-policy"],
  [Paths.CookiePolicy, "/cookie-policy"],
  [Paths.ManageCookies, "/manage-cookies"],
  [Paths.Sitemap, "/sitemap"],
  [Paths.Emergency, "/emergency"],
  [Paths.Home, "/"],
]);

//TODO: add maps for other languages

//translation to path
export const nlStringToPathsMap = new Map<string, Paths>([
  [`/${NL_SOLUTIONS}`, Paths.Solutions],
  [`/${NL_SECOND_HAND_INVENTORY}`, Paths.SecondHandInventory],
  [`/${NL_JOBS}`, Paths.Jobs],
  [`/${NL_CASES}`, Paths.Cases],
  [`/${NL_BLOGS}`, Paths.Blogs],
  [`/${NL_ABOUT_US}`, Paths.AboutUs],
  [`/${NL_CONTACT}`, Paths.Contact],
  [`/${NL_PRIVACY_POLICY}`, Paths.PrivacyPolicy],
  [`/${NL_COOKIE_POLICY}`, Paths.CookiePolicy],
  [`/${NL_MANAGE_COOKIES}`, Paths.ManageCookies],
  [`/${NL_SITEMAP}`, Paths.Sitemap],
  [`/${NL_EMERGENCY}`, Paths.Emergency],
  ["/", Paths.Home],
]);

export const frStringToPathsMap = new Map<string, Paths>([
  [`/${FR_SOLUTIONS}`, Paths.Solutions],
  [`/${NL_SECOND_HAND_INVENTORY}`, Paths.SecondHandInventory],
  [`/${FR_JOBS}`, Paths.Jobs],
  [`/${FR_CASES}`, Paths.Cases],
  [`/${FR_BLOGS}`, Paths.Blogs],
  [`/${FR_ABOUT_US}`, Paths.AboutUs],
  [`/${FR_CONTACT}`, Paths.Contact],
  [`/${FR_PRIVACY_POLICY}`, Paths.PrivacyPolicy],
  [`/${FR_COOKIE_POLICY}`, Paths.CookiePolicy],
  [`/${FR_MANAGE_COOKIES}`, Paths.ManageCookies],
  [`/${FR_SITEMAP}`, Paths.Sitemap],
  [`/${FR_EMERGENCY}`, Paths.Emergency],
  ["/", Paths.Home],
]);

export const enStringToPathsMap = new Map<string, Paths>([
  ["/solutions", Paths.Solutions],
  ["/second-hand-inventory", Paths.SecondHandInventory],
  ["/jobs", Paths.Jobs],
  ["/cases", Paths.Cases],
  ["/blogs", Paths.Blogs],
  ["/about-us", Paths.AboutUs],
  ["/contact", Paths.Contact],
  ["/privacy-policy", Paths.PrivacyPolicy],
  ["/cookie-policy", Paths.CookiePolicy],
  ["/manage-cookies", Paths.ManageCookies],
  [`/sitemap`, Paths.Sitemap],
  [`/emergency`, Paths.Emergency],
  ["/", Paths.Home],
]);
