import { IWithChildren } from "@lib/interfaces";
import { Svg } from "components";
import Link from "next/link";
import React from "react";

interface ButtonProps extends IWithChildren {
  href?: string;
  icon?: string;
  size: "small" | "large";
  color: "green" | "blue" | "gray";
  disabled?: boolean;
  as?: React.ElementType;
  isFormSubmit?: Boolean;
  onClick?: () => void;
  external?: boolean;
}

const Button = ({
  children,
  href = "",
  icon,
  size,
  disabled,
  color,
  as,
  onClick,
  isFormSubmit = false,
  external = false,
}: ButtonProps) => {
  const isSmall = size === "small";
  const isLarge = size === "large";
  const classes = [
    "inline-flex items-center gap-4",
    "whitespace-nowrap",

    isSmall && "h-10 px-4 min-w-[180px] justify-between",
    isLarge && "justify-center",
    isLarge && "min-w-full h-14 px-10 md:min-w-0 lg:h-20",
    isLarge && "text-lg lg:text-2xl",
    disabled &&
      "bg-opacity-50 bg-brand-green hover:bg-opacity-50 hover:bg-brand-green pointer-events-none",
    color === "green" && "text-white bg-brand-green hover:bg-brand-green-hover",
    color === "gray" && "text-white bg-brand-blue hover:bg-brand-blue-hover",
    color === "blue" && "text-white bg-brand-blue hover:bg-brand-blue-hover",
    "transition-colors",
  ]
    .filter(Boolean)
    .join(" ");

  const content = (
    <React.Fragment>
      {/* this font is so bad we need to address this padding bottom.. */}
      <div className="pb-1">{children}</div>
      {isSmall && icon && <Svg className="w-3 h-3" icon={icon} title="" />}
      {isLarge && icon && (
        <Svg className="w-4 h-4 lg:w-6 lg:h-6" icon={icon} title="" />
      )}
    </React.Fragment>
  );

  if (as) {
    const Component = as;

    if (isFormSubmit) {
      return (
        <Component className={classes} type="submit">
          {content}
        </Component>
      );
    }

    return (
      <Component onClick={onClick} className={classes}>
        {content}
      </Component>
    );
  }

  return (
    <Link href={href} passHref>
      <a className={classes} target={external ? "_blank" : undefined}>
        {content}
      </a>
    </Link>
  );
};

export { Button };
