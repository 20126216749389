import { APITypes } from "@lib/api.types";
import { SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { CardTeaser, TeaserCarousel } from "components";
import { useRouter } from "next/router";

interface SolutionsProps {
  solutions: APITypes.StrapiData<APITypes.Collections.Solution>[];
}

const SolutionsCarousel = ({ solutions }: SolutionsProps) => {
  const { locale } = useRouter();

  return (
    <TeaserCarousel numberOfChildren={solutions.length}>
      {solutions.map(({ attributes, id }) => {
        const imageSrc = attributes.illustration || attributes.hero_image;
        return (
          <div className="relative w-full flex-grow-0 flex-shrink-0" key={id}>
            <CardTeaser
              title={attributes.name}
              description={attributes.description}
              url={`${getLocalizedPath(
                Paths.Solutions,
                locale as SupportedLocales
              )}/${attributes.slug}`}
              buttonText={attributes.button_text}
              image={imageSrc.data?.attributes}
              animation="/animation.json"
            />
          </div>
        );
      })}
    </TeaserCarousel>
  );
};

export { SolutionsCarousel };
