import qs from "qs";
import { APITypes } from "./api.types";
import { SupportedLocales } from "./localization";

export enum Endpoints {
  Homepage = "home-page",
  BlogsPage = "blogs-page",
  CasesPage = "cases-page",
  SolutionsPage = "solutions-page",
  JobsPage = "jobs-page",
  AboutPage = "about-page",
  ContactPage = "contact-page",
  PrivacyPolicyPage = "privacy-policy-page",
  CookiePolicyPage = "cookie-policy-page",
  NavigationBar = "navigation-bar",
  Solutions = "solutions",
  Blogs = "blogs",
  Cases = "cases",
  Jobs = "jobs",
  Items = "items",
  MapLocations = "map-locations",
  Global = "global",
}

export default class API {
  private fetchNavigationProps;
  private fetchGlobalProps;

  constructor(private locale: SupportedLocales = "all") {
    this.fetchNavigationProps = {
      populate: {
        solutions: {
          populate: {
            items: "*",
            solution: { populate: { icon: "*" } },
          },
        },
      },
      locale: this.locale,
    };
    this.fetchGlobalProps = {
      populate: {
        location: {
          populate: {
            address: "*",
          },
        },
        socials: {
          populate: {
            icon: "*",
          },
        },
      },
      locale: this.locale,
    };
  }

  async fetchNavigation() {
    return this.getSingle<APITypes.Components.NavigationBar>(
      Endpoints.NavigationBar,
      this.fetchNavigationProps
    );
  }

  fetchNavigationURL(): string {
    return this.getURL(Endpoints.NavigationBar, this.fetchNavigationProps);
  }

  fetchGlobalURL(): string {
    return this.getURL(Endpoints.Global, this.fetchGlobalProps);
  }

  async fetchHomePage() {
    return this.getSingle<APITypes.Pages.Home>(Endpoints.Homepage, {
      populate: {
        Hero: { populate: "*" },
        WhyGENS: {
          populate: {
            content_blocks: { populate: { icon: "*" } },
            Button: "*",
          },
        },
        Button: "*",
        Contact: { populate: { left_button: "*", right_button: "*" } },
        seo: "*",
      },
      locale: this.locale,
    });
  }

  async fetchSolutionsPage() {
    return this.getSingle<APITypes.Pages.Solutions>(Endpoints.SolutionsPage, {
      populate: {
        Hero: { populate: "*" },
        seo: "*",
        Contact: { populate: { left_button: "*", right_button: "*" } },
      },
      locale: this.locale,
    });
  }

  async fetchBlogsPage() {
    return this.getSingle<APITypes.Pages.Blogs>(Endpoints.BlogsPage, {
      populate: { Hero: { populate: "*" }, seo: "*" },
      locale: this.locale,
    });
  }

  async fetchCasesPage() {
    return this.getSingle<APITypes.Pages.Cases>(Endpoints.CasesPage, {
      populate: {
        Hero: { populate: "*" },
        seo: "*",
        FeaturedCases: { populate: { cases: { populate: "*" } } },
        Contact: { populate: { left_button: "*", right_button: "*" } },
      },
      locale: this.locale,
    });
  }

  async fetchJobsPage() {
    return this.getSingle<APITypes.Pages.Jobs>(Endpoints.JobsPage, {
      populate: {
        Hero: { populate: "*" },
        seo: "*",
        FeaturedJobs: { populate: { jobs: { populate: "*" } } },
      },
      locale: this.locale,
    });
  }

  async fetchAboutPage() {
    return this.getSingle<APITypes.Pages.About>(Endpoints.AboutPage, {
      populate: {
        Hero: { populate: "*" },
        seo: "*",
        InformativeCompanyContent: { populate: "*" },
        FeaturedTeamMembers: {
          populate: {
            team_members: { populate: { image: { populate: "*" } } },
          },
        },
        FeaturedLocations: { populate: { locations: { populate: "*" } } },
        Contact: { populate: { left_button: "*", right_button: "*" } },
      },
      locale: this.locale,
    });
  }

  async fetchContactPage() {
    return this.getSingle<APITypes.Pages.Contact>(Endpoints.ContactPage, {
      populate: {
        Hero: { populate: "*" },
        seo: "*",
        InfoCards: { populate: "*" },
        FeaturedLocations: { populate: { locations: { populate: "*" } } },
      },
      locale: this.locale,
    });
  }

  fetchPrivacyPolicyPage = () =>
    this.fetchPolicyPage(Endpoints.PrivacyPolicyPage);

  fetchCookiePolicyPage = () =>
    this.fetchPolicyPage(Endpoints.CookiePolicyPage);

  private async fetchPolicyPage(endpoint: Endpoints) {
    return this.getSingle<APITypes.Pages.Policy>(endpoint, {
      populate: {
        page_content_blocks: "*",
        Contact: { populate: { left_button: "*", right_button: "*" } },
      },
      locale: this.locale,
    });
  }

  async fetchGlobal() {
    return this.getSingle<APITypes.Global>(Endpoints.Global, {
      locale: this.locale,
    });
  }

  async fetchAllSolutions(
    populate?: APITypes.Populate<APITypes.Collections.Solution>
  ) {
    return this.getCollection<APITypes.Collections.Solution>(
      Endpoints.Solutions,
      {
        populate: populate ?? {
          hero_image: "*",
          illustration: "*",
          icon: "*",
          localizations: "*",
          items: "*",
        },
        locale: this.locale,
      }
    );
  }

  async fetchAllBlogs() {
    return this.getCollection<APITypes.Collections.Blog>(Endpoints.Blogs, {
      populate: {
        Hero: { populate: "*" },
        tags: "*",
        page_content_blocks: {
          populate: {
            image: "*",
            buttons: "*",
          },
        },
      },
      locale: this.locale,
    });
  }
  async fetchAllCases() {
    return this.getCollection<APITypes.Collections.Case>(Endpoints.Cases, {
      locale: this.locale,
    });
  }

  async fetchAllJobs() {
    return this.getCollection<APITypes.Collections.Job>(Endpoints.Jobs, {
      locale: this.locale,
    });
  }

  async fetchAllMapLocations() {
    return this.getCollection<APITypes.Collections.MapLocation>(
      Endpoints.MapLocations,
      {
        locale: this.locale,
        populate: { case: "*" },
      }
    );
  }

  async fetchAllItems({
    includeSolution = false,
  }: {
    includeSolution: boolean;
  }) {
    const options: APITypes.StrapiQuery<APITypes.Collections.Item> = {
      locale: this.locale,
    };
    if (includeSolution) options.populate = { solution: "*" };
    return this.getCollection<APITypes.Collections.Item>(
      Endpoints.Items,
      options
    );
  }

  async fetchItems(
    localeToFetch: SupportedLocales,
    solutionSlug: string,
    pagination?: { page: number; pageSize: number }
  ) {
    return this.getCollection<APITypes.Collections.Item>(Endpoints.Items, {
      filters: {
        solution: { slug: { $eq: solutionSlug } },
      },
      locale: localeToFetch,
      populate: { icon: "*", media: "*" },
      pagination:
        pagination != null
          ? { page: pagination.page, pageSize: pagination.pageSize }
          : undefined,
    });
  }

  async fetchBlogs(
    localeToFetch: SupportedLocales,
    pagination?: { page: number; pageSize: number }
  ) {
    return this.getCollection<APITypes.Collections.Blog>(Endpoints.Blogs, {
      locale: localeToFetch,
      populate: {
        Hero: { populate: "*" },
        tags: "*",
        page_content_blocks: {
          populate: {
            image: "*",
            buttons: "*",
          },
        },
      },
      pagination:
        pagination != null
          ? { page: pagination.page, pageSize: pagination.pageSize }
          : undefined,
    });
  }

  fetchBlogBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.Blog>(Endpoints.Blogs, slug, {
      page_content_blocks: "*",
      Hero: { populate: "*" },
      seo: {
        populate: {
          meta_image: "*",
          meta_social: "*",
        },
      },
      localizations: "*",
    });

  fetchCaseBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.Case>(Endpoints.Cases, slug, {
      media: "*",
      page_content_blocks: "*",
      localizations: "*",
    });

  fetchJobBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.Job>(Endpoints.Jobs, slug, {
      Hero: { populate: "*" },
      seo: "*",
      tags: "*",
      department: "*",
      location: "*",
      description: "*",
      localizations: "*",
    });

  fetchSolutionBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.Solution>(Endpoints.Solutions, slug, {
      items: {
        populate: {
          page_content_blocks: {
            populate: {
              image: "*",
              buttons: "*",
            },
          },
          media: "*",
          localizations: "*",
        },
      },
      hero_image: "*",
      type: "*",
      localizations: "*",
    });

  fetchItemBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.Item>(Endpoints.Items, slug, {
      page_content_blocks: {
        populate: {
          image: "*",
          buttons: "*",
        },
      },
      media: "*",
      localizations: "*",
    });

  private async fetchBySlug<T>(
    endpoint: Endpoints,
    slug: string,
    populate: APITypes.Populate<T> = "*"
  ) {
    return this.getCollection<T>(endpoint, {
      populate,
      filters: { slug: { $eq: slug } },
      locale: this.locale,
    });
  }

  async getSingle<T = any>(
    path: string,
    options?: APITypes.StrapiQuery<T>
  ): Promise<APITypes.StrapiResult<APITypes.StrapiData<T>>> {
    return this.get<T, APITypes.StrapiResult<APITypes.StrapiData<T>>>(
      path,
      options
    );
  }

  async getCollection<T = any>(
    path: string,
    options?: APITypes.StrapiQuery<T>
  ): Promise<APITypes.StrapiResult<APITypes.StrapiData<T>[]>> {
    return this.get<T, APITypes.StrapiResult<APITypes.StrapiData<T>[]>>(
      path,
      options
    );
  }

  private constructQuery<T>({
    fields,
    populate,
    filters,
    locale,
    pagination,
  }: APITypes.StrapiQuery<T>): string {
    let queryObject: APITypes.StrapiQuery<T> = {};

    if (populate) queryObject.populate = populate;
    if (filters) queryObject.filters = filters;
    if (fields) queryObject.fields = fields;
    if (locale) queryObject.locale = locale;
    if (pagination) queryObject.pagination = pagination;

    return qs.stringify(queryObject, {
      encodeValuesOnly: true,
    });
  }

  private getURL<T>(path: string, options?: APITypes.StrapiQuery<T>): string {
    let query;
    if (options) {
      query = this.constructQuery<T>(options);
    }

    let url = `${process.env.NEXT_PUBLIC_STRAPI_API_URL}/${path}`;
    if (query) url = `${url}?${query}`;
    return url;
  }

  private async get<T, R>(
    path: string,
    options?: APITypes.StrapiQuery<T>
  ): Promise<R> {
    const url = this.getURL(path, options);
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }

    return json;
  }
}
