import React, { useMemo } from "react";
import { APITypes } from "@lib/api.types";
import { Block, ButtonWrapper, Image } from "components";
import { BreadCrumbs } from "@components/BreadCrumbs";
import { useRouter } from "next/router";

export type IHero = Omit<APITypes.Components.Hero, "id">;

export interface HeroProps {
  hero: IHero;
}

const Hero = ({ hero }: HeroProps) => {
  const { isReady } = useRouter();
  const { background_image, title, text, button } = hero;
  // When the hero is constructed using other data than the strapi hero component,
  // media might be an array.
  const bgImage: APITypes.StrapiData<APITypes.Components.Media> | undefined =
    useMemo(() => {
      if (background_image && background_image.data && isReady) {
        return Array.isArray(background_image?.data)
          ? background_image.data[0]
          : background_image.data;
      }
    }, [background_image, isReady]);

  return (
    <React.Fragment>
      <div className="relative | max-w-[1440px] | mx-auto | lg:p-10 lg:pb-0">
        <div className="absolute left-10 top-10 | lg:w-10 lg:h-10 xl:w-20 xl:h-20 | bg-brand-green | header-content-artefact" />
        <header className="relative max-w-[1360px] mx-auto">
          <div className="lg:w-[60%] lg:ml-[40%] | bg-brand-green lg:bg-transparent">
            <div className="relative | w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] | mobile-header-image-cutout">
              {bgImage ? (
                <Image
                  className="object-cover bg-brand-blue "
                  image={bgImage.attributes}
                  layout="fill"
                  alt={title}
                />
              ) : null}
            </div>
          </div>
          <div
            className="
              lg:absolute lg:top-0 lg:left-0
              flex flex-col
              lg:w-[60%] lg:h-[696px] |
              -mt-8 lg:mt-0 |
              bg-brand-dark-blue text-white |
              header-content-cutout
            "
          >
            <div className="h-8 lg:hidden" />
            <div className="grid gap-4 | px-6 pt-4 pb-8 lg:p-16 lg:pt-24 xl:pl-[120px] xl:pt-[160px] xl:pr-[70px]">
              <h1 className="max-w-2xl | font-display font-bold | text-3xl lg:text-4xl xl:text-5xl">
                {title}
              </h1>
              {text && (
                <div className="max-w-2xl lg:text-xl xl:text-2xl">{text}</div>
              )}
              {button && (
                <div className="max-w-sm">
                  <div className="lg:h-10"></div>
                  {/* TODO: add button action!*/}
                  <ButtonWrapper button={button} size="large">
                    {button.text}
                  </ButtonWrapper>
                </div>
              )}
            </div>
            {/* <div className="h-12 lg:h-24 | lg:mt-auto lg:mb-0 | bg-brand-side-blue" /> */}
          </div>
        </header>
        <div className="hidden md:block | w-full | px-10 mt-12 -mb-40 lg:mt-40 lg:px-0">
          <Block width="md">
            <BreadCrumbs />
          </Block>
        </div>
      </div>
      <div id="content" />
      <div className="sm:-mb-24" />
    </React.Fragment>
  );
};

export { Hero };
