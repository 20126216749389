import { useState, useEffect } from "react";

import { Svg } from "components";

type DarkModeSwitchProps = {
  className?: string;
  isDarkMode: boolean;
  setIsDarkMode: (isDarkMode: boolean) => void;
};

const DarkModeSwitch = ({
  className = "",
  isDarkMode,
  setIsDarkMode,
}: DarkModeSwitchProps) => {
  const activateDarkMode = () => {
    document.body.classList.add("dark");
    setIsDarkMode(true);

    window.localStorage.setItem("darkMode", "yes");
  };

  const deactivateDarkMode = () => {
    document.body.classList.remove("dark");
    setIsDarkMode(false);

    window.localStorage.setItem("darkMode", "no");
  };

  const toggleDarkMode = () => {
    isDarkMode ? deactivateDarkMode() : activateDarkMode();
  };

  useEffect(() => {
    const localStorageDarkMode = window.localStorage.getItem("darkMode");

    if (localStorageDarkMode === "yes") {
      activateDarkMode();
      return;
    }

    if (localStorageDarkMode === "no") {
      deactivateDarkMode();
      return;
    }

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      activateDarkMode();
    }
  }, []);

  return (
    <button
      className={`group | relative | ${className} `}
      onClick={toggleDarkMode}
    >
      <Svg
        className="block dark:hidden | w-6 h-6"
        icon="dark-mode-night"
        title="Enable dark mode"
      />
      <Svg
        className="hidden dark:block | w-6 h-6"
        icon="dark-mode-day"
        title="Disable dark mode"
      />
      {!isDarkMode && (
        <div
          className="
              absolute left-1/2 top-full
              hidden items-center xl:group-hover:flex
              h-8
              mt-3
              px-3 pb-1
              whitespace-nowrap
              bg-brand-side-blue text-white
              -translate-x-1/2
            "
        >
          <div className="tooltip-caret" />
          Switch to save energy
        </div>
      )}
    </button>
  );
};

export { DarkModeSwitch };
