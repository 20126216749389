import {
  Block,
  ButtonWrapper,
} from 'components';

const SingleButtonBlock = ({ block } : { block: any }) => {
  const button = {
    ...block,
  };

  return (
    <Block>
      <div className="grid | max-w-[480px] | mx-auto">
        {button && (
          <ButtonWrapper button={button} size="large" />
        )}
      </div>
    </Block>
  );
}

export default SingleButtonBlock;
