import { useField } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { TAutoForm } from "./AutoForm.types";

interface AutoFormInputFieldProps
  extends Omit<TAutoForm.IInputProps, "options"> {
  type?: "file";
}

const AutoFormInputField = ({
  name,
  label,
  placeholder = "",
  required = false,
  type = "file",
  error,
  onFileChange,
  translate,
}: AutoFormInputFieldProps) => {
  const [input] = useField({
    name,
    type,
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getFileName = async () => {
      const value = await input.value;
      setFileName(value.name);
    };

    getFileName();
  }, [input]);

  return (
    <label htmlFor="raised-button-file">
      <div>
        {label}
        <div className="mt-2">
          <label
            style={{ pointerEvents: "none" }}
            className={`
            flex items-center
            w-full h-14 px-4
            bg-brand-gray dark:bg-opacity-30
            text-black text-opacity-50 dark:text-white dark:text-opacity-50
            focus:outline-none
            flex justify-between
            ${
              error &&
              "border-2 border-brand-green bg-brand-green bg-opacity-15"
            }
            pointer-events:none;
          `}
          >
            <div>
              {fileName != null ? (
                <div className="text-black text-opacity-100">{fileName}</div>
              ) : (
                <div>{"No file selected yet"}</div>
              )}
              {required && <span className="text-brand-green">*</span>}
            </div>
            <div className="mt-2">
              <input
                ref={inputRef}
                type={type}
                style={{ display: "none" }}
                name={input.name}
                placeholder={placeholder}
                accept=".pdf, .doc, .docx, .txt"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (onFileChange != null) onFileChange(event);
                  setFileName(event.target.value.split("\\").pop());
                }}
                onSelect={(e) => e.stopPropagation()}
              />
              <button
                style={{ pointerEvents: "auto" }}
                type="button"
                className="mb-2 mr-2 py-1 px-4 border-0 text-sm font-light bg-white hover:cursor-pointer text-black text-opacity-80 dark:text-white dark:text-opacity-80"
                onClick={() =>
                  inputRef.current != null ? inputRef.current.click() : null
                }
              >
                {`${translate("uploadFile")}`}
              </button>
            </div>
          </label>
        </div>
        <div className={`text-brand-green font-medium text-sm`}>{error}</div>
      </div>
    </label>
  );
};

export { AutoFormInputField };
