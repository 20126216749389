//TODO: link this with translations file?
//NL
const NL_SOLUTIONS = "oplossingen";
const NL_SECOND_HAND_INVENTORY = "tweedehandsinventaris";
const NL_JOBS = "jobs";
const NL_CASES = "realisaties";
const NL_BLOGS = "blogs";
const NL_ABOUT_US = "over-ons";
const NL_CONTACT = "contact";
const NL_MORE = "meer";
const NL_PRIVACY_POLICY = "privacy-policy";
const NL_COOKIE_POLICY = "cookie-policy";
const NL_MANAGE_COOKIES = "manage-cookies";
const NL_SITEMAP = "sitemap";
const NL_EMERGENCY = "noodgeval";

//FR
const FR_SOLUTIONS = "solutions";
const FR_SECOND_HAND_INVENTORY = "inventaire-d'occasion";
const FR_JOBS = "postes-vacants";
const FR_CASES = "realisations";
const FR_BLOGS = "blogues";
const FR_ABOUT_US = "qui-sommes-nous";
const FR_CONTACT = "contact";
const FR_SITEMAP = "sitemap";
const FR_EMERGENCY = "noodgeval";
//TODO translate policy
const FR_PRIVACY_POLICY = "privacy-policy";
const FR_COOKIE_POLICY = "cookie-policy";
const FR_MANAGE_COOKIES = "manage-cookies";
const FR_MORE = "plus";
const paths = {
  solutions: {
    nl: NL_SOLUTIONS,
    fr: FR_SOLUTIONS,
    /** de: "" */
  },
  ["solutions/:solution"]: {
    nl: `${NL_SOLUTIONS}/:solution`,
    fr: `${FR_SOLUTIONS}/:solution`,
  },
  ["solutions/:solution/:item"]: {
    nl: `${NL_SOLUTIONS}/:solution/:item`,
    fr: `${FR_SOLUTIONS}/:solution/:item`,
  },
  jobs: {
    nl: NL_JOBS,
    fr: FR_JOBS,
  },
  ["jobs/:slug"]: {
    nl: `${NL_JOBS}/:slug`,
    fr: `${FR_JOBS}/:slug`,
  },
  cases: {
    nl: NL_CASES,
    fr: FR_CASES,
  },
  ["cases/:slug"]: {
    nl: `${NL_CASES}/:slug`,
    fr: `${FR_CASES}/:slug`,
  },
  blogs: {
    nl: NL_BLOGS,
    fr: FR_BLOGS,
  },
  ["blogs/:slug"]: {
    nl: `${NL_BLOGS}/:slug`,
    fr: `${FR_BLOGS}/:slug`,
  },
  ["about-us"]: {
    nl: NL_ABOUT_US,
    fr: FR_ABOUT_US,
  },
  ["privacy-policy"]: {
    nl: NL_PRIVACY_POLICY,
    fr: FR_PRIVACY_POLICY,
  },
  ["cookie-policy"]: {
    nl: NL_COOKIE_POLICY,
    fr: FR_COOKIE_POLICY,
  },
  contact: {
    nl: NL_CONTACT,
    fr: FR_CONTACT,
  },
  more: {
    nl: NL_MORE,
    fr: FR_MORE,
  },
  sitemap: {
    nl: NL_SITEMAP,
    fr: FR_SITEMAP,
  },
  emergency: {
    nl: NL_EMERGENCY,
    fr: FR_EMERGENCY,
  },
};

const getRewrites = () => {
  const nlRewrites = Object.keys(paths).map((path) => ({
    source: `/${paths[path].nl}`,
    destination: `/${path}`,
  }));
  //TODO: add other rewrites here
  return [...nlRewrites];
};

module.exports = {
  NL_SOLUTIONS,
  NL_SECOND_HAND_INVENTORY,
  NL_JOBS,
  NL_CASES,
  NL_BLOGS,
  NL_ABOUT_US,
  NL_CONTACT,
  NL_COOKIE_POLICY,
  NL_PRIVACY_POLICY,
  NL_MANAGE_COOKIES,
  NL_MORE,
  NL_SITEMAP,
  FR_SOLUTIONS,
  FR_SECOND_HAND_INVENTORY,
  FR_JOBS,
  FR_CASES,
  FR_BLOGS,
  FR_ABOUT_US,
  FR_CONTACT,
  FR_COOKIE_POLICY,
  FR_PRIVACY_POLICY,
  FR_MANAGE_COOKIES,
  FR_MORE,
  FR_SITEMAP,
  paths,
  NL_EMERGENCY,
  FR_EMERGENCY,
  getRewrites,
};
