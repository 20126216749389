import {
  Formik,
  Form,
  FormikHelpers,
  FormikErrors,
  FormikTouched,
  FormikValues,
} from "formik";
import {
  AutoFormTextField,
  AutoFormTextareaField,
  AutoFormSelectField,
  AutoFormSubmit,
  AutoFormStatus,
  AutoFormNumberField,
} from "components";
import { EAutoFormComponents, TAutoForm } from "./AutoForm.types";
import { AutoFormInputField } from "./AutoFormInputField";
import { TFunction } from "next-i18next";

type FormikSubmitFunction<T> = ((
  values: T,
  formikHelpers: FormikHelpers<{}>
) => void | Promise<any>) &
  Function;

type AutoFormProps<T> = {
  initialValues: FormikValues;
  validationSchema: {};
  onSubmit: FormikSubmitFunction<FormikValues>;
  structure: TAutoForm.IField[];
  translate: TFunction;
};

const AutoForm = <T,>({
  initialValues,
  validationSchema,
  onSubmit,
  structure,
  translate,
}: AutoFormProps<T>) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isValid,
        setFieldValue,
        setFieldTouched,
      }: {
        errors: FormikErrors<TAutoForm.IField>;
        touched: FormikTouched<TAutoForm.IField>;
        isValid: boolean;
        setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean
        ) => void;
        setTouched: (
          fields: { [field: string]: boolean },
          shouldValidate?: boolean
        ) => void;
        setFieldTouched: (
          field: string,
          isTouched?: boolean,
          shouldValidate?: boolean
        ) => void;
      }) => {
        return (
          <Form className="grid md:grid-cols-12 gap-x-4 gap-y-6 sm:gap-y-8 | w-full">
            {structure.map((field: TAutoForm.IField, index: number) => {
              const error =
                errors[field.name as keyof TAutoForm.IField] &&
                touched[field.name as keyof TAutoForm.IField]
                  ? errors[field.name as keyof TAutoForm.IField]
                  : null;
              return (
                <div
                  className={`md:col-span-${field.width ?? 12}`}
                  key={`${field.name}-${index}`}
                >
                  {field.type === EAutoFormComponents.Text && (
                    <AutoFormTextField
                      name={field.name}
                      label={field.label}
                      error={error}
                      {...field.settings}
                      translate={translate}
                    />
                  )}
                  {field.type === EAutoFormComponents.Tel && (
                    <AutoFormNumberField
                      name={field.name}
                      label={field.label}
                      error={error}
                      {...field.settings}
                      translate={translate}
                    />
                  )}

                  {field.type === EAutoFormComponents.Textarea && (
                    <AutoFormTextareaField
                      name={field.name}
                      label={field.label}
                      error={error}
                      {...field.settings}
                      translate={translate}
                    />
                  )}

                  {field.type === EAutoFormComponents.Select && (
                    <AutoFormSelectField
                      name={field.name}
                      label={field.label}
                      options={field.options ?? []}
                      error={error}
                      {...field.settings}
                      onSelect={field.onSelect}
                      translate={translate}
                    />
                  )}
                  {field.type === EAutoFormComponents.File && (
                    <AutoFormInputField
                      name={field.name}
                      label={field.label}
                      error={error}
                      {...field.settings}
                      onFileChange={async (
                        value: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const files = value.target.files;
                        if (files != null) {
                          setFieldValue(value.target.name, files[0], true);
                          setFieldTouched(field.name, true, false);
                        }
                      }}
                      translate={translate}
                    />
                  )}

                  {field.type === EAutoFormComponents.Submit && (
                    <AutoFormSubmit
                      label={field.label}
                      disabled={!isValid}
                      translate={translate}
                    />
                  )}

                  {field.type === EAutoFormComponents.Status && (
                    <AutoFormStatus />
                  )}
                </div>
              );
            })}
          </Form>
        );
      }}
    </Formik>
  );
};

export { AutoForm };
