import { APITypes } from "@lib/api.types";
import { SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { SanitizeString } from "@lib/SanitizeString";
import { INavigationItem } from "./Navigation.types";

export enum NavigationItems {
  Solutions = "Solutions",
  Cases = "Cases",
  AboutUs = "About Us",
  Jobs = "Jobs",
  Blogs = "Blogs",
  Contact = "Contact",
  Sitemap = "Sitemap",
  Emergency = "Emergency",
}

export const createTopLevelNavigationItem = (
  title: string,
  path?: string,
  locale: SupportedLocales = "en",
  isDynamic: boolean = false
): INavigationItem => {
  const localizedPath = getLocalizedPath(path as Paths, locale);
  const asTitle = `${localizedPath[1].toUpperCase()}${localizedPath
    .slice(2, localizedPath.length)
    .replace("-", " ")}`;

  return {
    title: isDynamic ? title : asTitle,
    url: path ? (isDynamic ? path : localizedPath) : "",
    active: false,
    children: [],
  };
};

export const createNavigationItem = (
  title: string,
  path?: string,
  locale: SupportedLocales = "en",
  isDynamic: boolean = false
): INavigationItem => {
  return {
    title: title,
    url: path ?? "",
    active: false,
    children: [],
  };
};

export const getBaseNavigation = (
  locale: SupportedLocales
): INavigationItem[] => {
  return [
    {
      ...createTopLevelNavigationItem(
        NavigationItems.Solutions,
        Paths.Solutions,
        locale
      ),
      dropdown: "extended",
      children: [],
    },
    createTopLevelNavigationItem(NavigationItems.Cases, Paths.Cases, locale),
    createTopLevelNavigationItem(
      NavigationItems.AboutUs,
      Paths.AboutUs,
      locale
    ),
    createTopLevelNavigationItem(NavigationItems.Jobs, Paths.Jobs, locale),
    createTopLevelNavigationItem(
      NavigationItems.Contact,
      Paths.Contact,
      locale
    ),
  ];
};

export const getBaseMobileNavigation = (
  locale: SupportedLocales
): INavigationItem[] => {
  return [
    {
      ...createTopLevelNavigationItem(
        NavigationItems.Solutions,
        Paths.Solutions,
        locale
      ),
      dropdown: "extended",
      children: [],
    },
    createTopLevelNavigationItem(NavigationItems.Cases, Paths.Cases, locale),
    createTopLevelNavigationItem(
      NavigationItems.AboutUs,
      Paths.AboutUs,
      locale
    ),
    createTopLevelNavigationItem(NavigationItems.Jobs, Paths.Jobs, locale),
    createTopLevelNavigationItem(
      NavigationItems.Contact,
      Paths.Contact,
      locale
    ),
  ];
};

export const getBaseFooter = (
  locale: SupportedLocales,
  global: APITypes.Global | null,
  cta_text: string,
  request_quote_text: string
): INavigationItem[] => {
  return [
    {
      ...createTopLevelNavigationItem(
        NavigationItems.Solutions,
        Paths.Solutions,
        locale
      ),
      children: [],
    },
    {
      ...createTopLevelNavigationItem(
        NavigationItems.Sitemap,
        Paths.Sitemap,
        locale
      ),
      dropdown: "simple",
      children: [
        createTopLevelNavigationItem(
          NavigationItems.AboutUs,
          Paths.AboutUs,
          locale
        ),
        createTopLevelNavigationItem(
          NavigationItems.Cases,
          Paths.Cases,
          locale
        ),
        createTopLevelNavigationItem(NavigationItems.Jobs, Paths.Jobs, locale),
        createTopLevelNavigationItem(
          NavigationItems.Contact,
          Paths.Contact,
          locale
        ),
      ],
    },
    {
      ...createTopLevelNavigationItem(
        NavigationItems.Contact,
        Paths.Contact,
        locale
      ),
      dropdown: "simple",
      children: [
        createNavigationItem(
          global?.location?.data?.attributes.address ?? "",
          undefined,
          locale
        ),
        createNavigationItem(
          global?.default_email_address ?? "",
          `mailto:${global?.default_email_address ?? ""}`,
          locale
        ),
        createNavigationItem(
          global?.default_phone_number ?? "",
          `tel:${global?.default_phone_number ?? ""}`,
          locale
        ),
      ],
    },
    {
      ...createTopLevelNavigationItem(
        NavigationItems.Emergency,
        Paths.Emergency,
        locale
      ),
      children: [
        createNavigationItem(
          cta_text,
          `tel:${global?.default_phone_number ?? ""}`,
          locale
        ),
      ],
    },
  ];
};
