import useGetGlobals from "@components/Footer/useGetGlobals";
import { DEFAULT_LANGUAGE, SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { Button, DarkModeSwitch, Svg } from "components";
import { TFunction } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { INavigationItem } from "./Navigation.types";
import { useCreateMobileNavigationMenu } from "./useCreateNavigationMenu";

const screens = {
  start: "START",
  submenu: "SUBMENU",
  languageSwitcher: "LANGUAGE_SWITCHER",
};

type MobileNavigationProps = {
  translate: TFunction;
  isDarkMode: boolean;
  setIsDarkMode: (isDarkMode: boolean) => void;
};

const MobileNavigation = ({
  translate,
  isDarkMode,
  setIsDarkMode,
}: MobileNavigationProps) => {
  const router = useRouter();
  const { navigationMenu, ctaText } = useCreateMobileNavigationMenu(
    (router.locale ?? DEFAULT_LANGUAGE) as SupportedLocales
  );

  const { globalData } = useGetGlobals(
    (router.locale ?? DEFAULT_LANGUAGE) as SupportedLocales
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [screen, setScreen] = useState(screens.start);
  const [activeSubmenu, setActiveSubmenu] = useState<INavigationItem | null>(
    null
  );

  const toggleMenu = () => {
    setScreen(screens.start);
    setMenuIsOpen(!menuIsOpen);

    // We remove scroll bar when open nav menu
    const body = document.getElementsByTagName("body")[0];
    const html = document.getElementsByTagName("html")[0];

    if (!menuIsOpen) {
      body.style.overflow = "hidden";
      body.style.position = "relative";
      body.style.height = "100%";
      html.style.position = "relative";
      html.style.overflow = "hidden";
      html.style.height = "100%";
    } else {
      body.style.overflow = "";
      body.style.position = "";
      body.style.overflow = "";
      html.style.position = "";
      html.style.overflow = "";
      html.style.overflow = "";
    }
  };

  const languages = [
    { value: "nl", label: "Nederlands", url: "/nl", flag: "nl" },
    { value: "en", label: "English", url: "/en", flag: "en" },
  ];

  const activeLanguage = languages.find(
    (language) => language.value === router.locale
  );

  return (
    <React.Fragment>
      <div className="sticky top-0 z-50 | xl:hidden | bg-white dark:bg-brand-night">
        <div className="px-6 lg:px-10">
          <div className="flex items-center justify-between | h-16">
            {screen === screens.start && (
              <React.Fragment>
                {menuIsOpen ? (
                  <button
                    className="text-brand-side-blue dark:text-white hover:text-brand-green | transition-colors"
                    onClick={toggleMenu}
                  >
                    <Svg
                      className="w-6 h-6"
                      icon="menu-close"
                      title="Open menu"
                    />
                  </button>
                ) : (
                  <button
                    className="text-brand-side-blue dark:text-white hover:text-brand-green | transition-colors"
                    onClick={toggleMenu}
                  >
                    <Svg className="w-6 h-6" icon="menu" title="Close menu" />
                  </button>
                )}
              </React.Fragment>
            )}
            {screen !== screens.start && (
              <button
                className="text-brand-side-blue dark:text-white hover:text-brand-green | transition-colors"
                onClick={() => setScreen(screens.start)}
              >
                <Svg className="w-6 h-6" icon="menu-back" title="Back" />
              </button>
            )}
            <a href={`/${router.locale}`}>
              {isDarkMode ? (
                <Image
                  src="/gens-logo-white.svg"
                  alt="logo"
                  width={200}
                  height={60}
                />
              ) : (
                <Image
                  src="/gens-logo-blue.svg"
                  alt="logo"
                  width={200}
                  height={60}
                />
              )}
            </a>
            <div className="w-6">
              <a
                className="text-brand-side-blue dark:text-white hover:text-brand-green | transition-colors"
                href="tel:+32474240240"
                onClick={toggleMenu}
              >
                <Svg className="w-6 h-6" icon="phone" title="Phone" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {menuIsOpen && (
        <div
          className="
              overflow-auto 
              fixed inset-0 z-40
              flex flex-col
              bg-brand-side-blue text-white
          "
        >
          <div className="h-16"></div>
          <div className="flex-grow | flex flex-col justify-between | p-6 pb-4">
            {screen === screens.start && (
              <ul className="grid gap-8">
                {navigationMenu?.map((item) => (
                  <li key={item.title}>
                    {item.children.length ? (
                      <button
                        className="flex items-center justify-between | w-full h-8 | hover:text-brand-green | transition-colors"
                        onClick={() => {
                          setScreen(screens.submenu);
                          setActiveSubmenu(item);
                        }}
                      >
                        <span>{item.title}</span>
                        <Svg className="w-4 h-4" icon="arrow-right" title="" />
                      </button>
                    ) : (
                      <Link href={item.url}>
                        <a
                          className="flex items-center | h-8 | hover:text-brand-green | transition-colors"
                          onClick={toggleMenu}
                        >
                          {item.title}
                        </a>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            )}
            {screen === screens.submenu &&
              activeSubmenu &&
              activeSubmenu.dropdown === "extended" && (
                <div className="grid gap-8 ">
                  <div className="h-10"></div>
                  {activeSubmenu.children.map((item) => (
                    <div key={item.title}>
                      <div className="flex items-center gap-2  | font-bold text-2xl">
                        {item.icon && (
                          <>
                            <Image
                              className="w-8 h-8"
                              src={`${process.env.NEXT_PUBLIC_STRAPI_URL}/${item.icon}`}
                              width={32}
                              height={32}
                              alt=""
                            />
                            <Link href={item.url} key={item.title}>
                              <a
                                className="hover:text-brand-green"
                                onClick={toggleMenu}
                              >
                                {item.title}
                              </a>
                            </Link>
                          </>
                        )}
                      </div>
                      <div className="grid gap-8 | mt-4">
                        {item.children.map((item) => (
                          <Link href={item.url} key={item.title}>
                            <a
                              className="hover:text-brand-green"
                              onClick={toggleMenu}
                            >
                              {item.title}
                            </a>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            {screen === screens.submenu &&
              activeSubmenu &&
              activeSubmenu.dropdown === "simple" && (
                <ul className="grid gap-4">
                  {activeSubmenu.children.map((item) => (
                    <li key={item.title}>
                      <a
                        className="flex items-center | h-8 | hover:text-brand-green | transition-colors"
                        href={item.url}
                        onClick={toggleMenu}
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            {screen === screens.languageSwitcher && (
              <ul className="grid gap-8">
                {languages.map((item) => (
                  <li key={item.flag}>
                    <a
                      className="flex items-center gap-4 | h-8 | hover:text-brand-green | transition-colors"
                      href={item.url}
                      onClick={toggleMenu}
                    >
                      <Svg
                        className="w-5"
                        icon={`flag-${item.flag}`}
                        title={item.label}
                        viewBox="0 0 640 480"
                      />
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {screen !== screens.submenu ? (
              <a
                className="block | leading-6 hover:text-brand-green | transition-colors"
                href={`tel:${globalData && globalData.default_phone_number}`}
                onClick={toggleMenu}
              >
                {ctaText}
                <br />
                <strong className="font-bold">
                  {`${translate("call")} ${
                    globalData && globalData.default_phone_number
                  }`}
                </strong>
              </a>
            ) : (
              <div className="max-w-sm">
                <div className="lg:h-10">
                  <Button
                    as={"button"}
                    size={"small"}
                    color={"green"}
                    href={getLocalizedPath(
                      Paths.Contact,
                      (router.locale ?? DEFAULT_LANGUAGE) as SupportedLocales
                    )}
                    icon={"arrow-right"}
                    onClick={toggleMenu}
                  >
                    {`${translate("requestQuote")}`}
                  </Button>
                </div>
              </div>
            )}
          </div>
          {screen !== screens.submenu && (
            <div className="flex items-center justify-between | h-[72px] | px-6 p-8 | bg-brand-dark-blue">
              <button
                className="flex items-center gap-2"
                onClick={() => {
                  setScreen(screens.languageSwitcher);
                }}
              >
                {activeLanguage && (
                  <React.Fragment>
                    <Svg
                      className="w-5"
                      icon={`flag-${activeLanguage.flag}`}
                      title={activeLanguage.label}
                      viewBox="0 0 640 480"
                    />
                    <span>{activeLanguage.label}</span>
                  </React.Fragment>
                )}
                <Svg
                  className="w-2.5"
                  icon="arrow-right"
                  title="Arrow Right"
                  viewBox="0 0 10 14"
                />
              </button>
              <DarkModeSwitch
                className="text-white hover:text-brand-green"
                isDarkMode={isDarkMode}
                setIsDarkMode={setIsDarkMode}
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export { MobileNavigation };
