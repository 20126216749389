import Footer from "@components/Footer";
import { IWithChildren } from "@lib/interfaces";
import { DesktopNavigation, MobileNavigation } from "components";
import { TFunction } from "next-i18next";
import { useEffect, useState } from "react";
import Meta, { IMeta } from "../Meta";

interface LayoutProps extends IWithChildren {
  translate: TFunction;
  slug?: string;
  meta?: IMeta;
}

const Layout = ({ children, translate, slug, meta }: LayoutProps) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
    const loadSvgSprite = async () => {
      const svgSpriteContainer = document.querySelector(
        "[data-svg-sprite-container]"
      );

      if (svgSpriteContainer) {
        const svgSprite = await fetch("/svg-sprite.svg");

        svgSpriteContainer.innerHTML = await svgSprite.text();
        // @ts-ignore:
        svgSpriteContainer.style.display = "none";
      }
    };

    loadSvgSprite().then();
  }, []);

  return (
    <div className="font-body font-[300] text-lg | bg-white text-black dark:bg-brand-night dark:text-white h-full">
      <Meta {...meta} />
      <div className="min-h-screen">
        <MobileNavigation
          translate={translate}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
        />
        <DesktopNavigation
          translate={translate}
          slug={slug}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
        />
        <DesktopNavigation
          translate={translate}
          activateSticky
          slug={slug}
          isDarkMode={isDarkMode}
          setIsDarkMode={setIsDarkMode}
        />
        <main>{children}</main>
      </div>
      <Footer translate={translate} />
      <div data-svg-sprite-container="" />
    </div>
  );
};

export { Layout };
