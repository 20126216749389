import { Button } from "components";

import { TAutoForm } from "./AutoForm.types";

interface AutoFormSubmitProps
  extends Pick<TAutoForm.IInputProps, "label" | "translate"> {
  disabled: boolean;
}

const AutoFormSubmit = ({
  label,
  disabled,
  translate,
}: AutoFormSubmitProps) => (
  <Button
    size="large"
    color="green"
    as="button"
    disabled={disabled}
    isFormSubmit
  >
    {label ?? "Save"}
  </Button>
);

export { AutoFormSubmit };
