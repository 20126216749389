import { APITypes } from "@lib/api.types";
import Image from "next/image";
import { useMemo } from "react";

interface UspBlockProps {
  index: number;
  contentBlock: APITypes.Components.ContentBlock;
}

function UspBlock({ index, contentBlock }: UspBlockProps) {
  const imageAttributes = useMemo(
    () => contentBlock.icon.data?.attributes,
    [contentBlock]
  );
  return (
    <div
      className={`
        flex-center flex-col gap-12
        px-10 py-12
        text-center
        bg-brand-green text-white odd:bg-brand-side-blue
        ${
          index < 3
            ? "md:col-span-2 lg:col-span-1"
            : "md:col-span-3 lg:col-span-1"
        }
      `}
    >
      {contentBlock.icon ? (
        <div className="flex-center | w-auto h-12">
          {imageAttributes && (
            <Image
              src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${imageAttributes.url}`}
              alt={imageAttributes.alternativeText}
              width={100}
              height={100}
            />
          )}
        </div>
      ) : null}
      <div className="max-w-[200px]">{contentBlock.title}</div>
    </div>
  );
}

export { UspBlock };
