import Link from "next/link";
import { useRouter } from "next/router";
import React, { useCallback, useMemo } from "react";

export function BreadCrumbs() {
  const { asPath, isReady } = useRouter();

  const allSubroutes: string[] = useMemo(() => {
    if (isReady) {
      const routeParts = asPath.split("/");
      const slug = routeParts[routeParts.length - 1];
      return routeParts.length === 0
        ? [slug]
        : routeParts.slice(1, routeParts.length).filter((str) => str !== "");
    }
    return [];
  }, [asPath, isReady]);

  const allRoutes = useMemo(
    () =>
      ["Home", ...allSubroutes]
        .map((r) => r[0].toUpperCase() + r.substring(1, r.length))
        .map((r) => r.replaceAll("-", " "))
        .map((r) => r.split("#")[0]),
    [allSubroutes]
  );

  const getLink = useCallback(
    (index: number) => {
      const link = allRoutes
        .slice(1, index + 1)
        .map((r) => r.toLowerCase())
        .map((r) => r.replaceAll(" ", "-"))
        .join("/");
      return "/" + link;
    },
    [allRoutes]
  );

  if (allRoutes.length > 1) {
    return (
      <div className="flex items-center gap-3 md:gap-4 lg:gap-6">
        {allRoutes.map((route, index) => {
          return (
            <React.Fragment key={route}>
              <Link href={getLink(index)}>
                <a
                  className={
                    index === allRoutes.length - 1
                      ? "font-bold text-brand-green"
                      : ""
                  }
                >
                  {route}
                </a>
              </Link>
              <svg
                className="last:hidden | w-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 8 12"
              >
                <path
                  fill="#383E5C"
                  d="M7.18 5.71 1.23.58C1.01.38.7.56.7.87v10.26c0 .3.32.48.54.29l5.95-5.13a.4.4 0 0 0 0-.58Z"
                />
              </svg>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  return <>{allSubroutes.map((s) => s.replace("-", " ")).join(" > ")}</>;
}
