import { SanitizeString } from "@lib/SanitizeString";
import { APITypes } from "@lib/api.types";

interface LocationsGridProps {
  locations: APITypes.StrapiData<APITypes.Collections.Location>[];
}

const LocationsGrid = ({ locations }: LocationsGridProps) => (
  <div
    className={`grid md:grid-cols-${
      locations.length === 1 ? 2 : locations.length
    }  m-auto justify-center`}
    style={{
      width: locations.length === 1 ? "50%" : "auto",
      maxWidth: "100%",
    }}
  >
    {locations.map(({ id, attributes }) => (
      <div
        key={id}
        className={`
          ${locations.length === 1 ? "col-span-2" : ""}
          flex flex-col items-center justify-center gap-9 lg:gap-12
          px-6 py-12
          text-center
          bg-brand-blue odd:bg-brand-green text-white
        `}
      >
        <h3 className="headline-3">{attributes.name}</h3>
        <p>
          <SanitizeString content={attributes.address} />
        </p>
        <p>
          {attributes.email && (
            <a className="" href={`mailto:${attributes.email}`}>
              {attributes.email}
            </a>
          )}
          <br />
          {attributes.phone_number && (
            <a className="" href={`tel:${attributes.phone_number}`}>
              {attributes.phone_number}
            </a>
          )}
        </p>
      </div>
    ))}
  </div>
);

export { LocationsGrid };
