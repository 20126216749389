import { useCallback, useEffect, useState } from "react";

import useEmblaCarousel from "embla-carousel-react";

import { IWithChildren } from "@lib/interfaces";

interface TeaserCarouselProps extends IWithChildren {
  numberOfChildren: number;
}

const TeaserCarousel = ({
  children,
  numberOfChildren,
}: TeaserCarouselProps) => {
  const [reference, embla] = useEmblaCarousel({
    align: "start",
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(
    (index: number) => {
      if (embla) embla.scrollTo(index);
    },
    [embla]
  );

  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setSelectedIndex(embla.selectedScrollSnap());
      });
    }
  }, [reference, embla]);

  return (
    <div>
      <div className="" ref={reference}>
        <div className="flex gap-6">{children}</div>
      </div>
      <div className="flex-center gap-2 | mt-6">
        {[...Array(numberOfChildren).keys()].map((index) => (
          <button
            key={index}
            className={`
              w-4 h-4
              border border-2 border-brand-side-blue
              rounded-full
              transition-colors
              ${selectedIndex === index && "bg-brand-green border-brand-green"}
            `}
            onClick={() => {
              scrollTo(index);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { TeaserCarousel };
