import {
  useFormikContext,
} from "formik";

const AutoFormStatus = () => {
  const {
    status,
  } = useFormikContext();

  if (status && status.error) {
    return (
      <div className="p-4 | bg-red-50 border border-red-100 rounded-sm">
        {status.message}
      </div>
    );
  }

  if (status && status.success) {
    return (
      <div className="p-4 | bg-green-50 border border-green-100 rounded-sm">
        {status.message}
      </div>
    );
  }

  return null;
};

export { AutoFormStatus };
