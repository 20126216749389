import { Block, Markdown, Image } from "components";

const TextWithImageBlock = ({ block }: any) => {
  return (
    <Block>
      <div className="grid gap-12 md:grid-cols-2 md:gap-8 lg:gap-16">
        <div className="md:order-2 | md:mt-6">
          <Markdown content={block.text} />
        </div>
        <div className="md:order-1 | w-screen md:w-full | -mx-6 sm:-mx-10 md:mx-auto">
          <Image
            className="w-full h-auto  | bg-gray-200 | content-builder-image-cutout"
            image={{
              ...block.image.data.attributes,
              width: "480px",
              height: "480px",
            }}
            alt={block.title}
          />
        </div>
      </div>
    </Block>
  );
};

export default TextWithImageBlock;
