type IconDefinition = {
    className: string,
    icon: string,
    title: string,
    viewBox?: string,
};

const Svg = ({ className = '', icon, title, viewBox = '0 0 24 24' } : IconDefinition) => {
    return (
        <svg className={`${className} | fill-current`} viewBox={viewBox}>
            <title>{title}</title>
            <use xlinkHref={`#svg-${icon}`} />
        </svg>
    );
};

export { Svg };