import { APITypes } from "@lib/api.types";
import { ButtonWrapper } from "..";
import { UspBlock } from "./UspBlock";

function WhyGENS({
  title,
  content_blocks,
  Button: button,
}: APITypes.Components.WhyGENS) {
  return (
    <>
      <h2 className="headline-3 lg:headline-2 lg:text-center">{title}</h2>
      <div className="h-6 sm:h-10 md:h-12 lg:h-16"></div>
      <div className="grid md:grid-cols-6 md:gap-2 lg:grid-cols-5 lg:gap-0">
        {content_blocks
          ? content_blocks.map((contentBlock, index) => {
              return (
                <UspBlock
                  key={contentBlock.id}
                  index={index}
                  contentBlock={contentBlock}
                />
              );
            })
          : null}
      </div>
      <div className="h-6 sm:h-10 md:h-12 lg:h-16"></div>
      {button ? (
        <div className="flex flex-center">
          <ButtonWrapper button={button} size="large">
            {button.text}
          </ButtonWrapper>
        </div>
      ) : null}
    </>
  );
}

export { WhyGENS };
