import { Option } from "@lib/types";
import { TFunction } from "next-i18next";
import { ChangeEvent } from "react";

export namespace TAutoForm {
  export type Fields =
    | "text"
    | "textarea"
    | "select"
    | "submit"
    | "status"
    | "tel"
    | "file";

  export interface IField {
    name: string;
    type: Fields;
    width: number;
    label: string;
    required?: boolean;
    settings?: Record<string, any>;
    options?: Option[];
    onSelect?: (value: string) => void;
    onFileChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  }

  export interface IInputProps {
    name: string;
    label: string;
    options: Option[];
    error: string | null | undefined;
    placeholder?: string;
    required?: Boolean;
    onSelect?: (value: string) => void;
    onFileChange?: (value: ChangeEvent<HTMLInputElement>) => void;
    translate: TFunction;
  }
}

export enum EAutoFormComponents {
  Text = "text",
  Textarea = "textarea",
  Select = "select",
  Submit = "submit",
  Status = "status",
  Tel = "tel",
  File = "file",
}
