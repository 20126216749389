import { useState, useEffect } from "react";
import markdownToHtml from "@lib/markdownToHtml";
import { sanitize } from "dompurify";

type MarkdownProps = {
  className?: string;
  content: string;
};

const Markdown = ({ className = "", content }: MarkdownProps) => {
  const [parsedContent, setParsedContent] = useState("");

  useEffect(() => {
    const parse = async () => {
      const xxx = content.replaceAll(
        "/uploads",
        `${process.env.NEXT_PUBLIC_STRAPI_URL}/uploads`
      );

      const unsafeContent = await markdownToHtml(xxx);
      const safeContent = sanitize(unsafeContent);

      setParsedContent(safeContent);
    };

    parse();
  }, [content]);

  return (
    <div
      className={`max-w-none | prose prose-lg dark:prose-invert ${className}`}
      dangerouslySetInnerHTML={{
        __html: parsedContent,
      }}
    />
  );
};

export { Markdown };
