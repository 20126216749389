import { APITypes } from "@lib/api.types";
import { SupportedLocales } from "@lib/localization";
import { getBlogItemUrl } from "@lib/routing/routing.util";
import { CardTeaser } from "components";

type BlogTeaserProps = {
  blog: APITypes.Collections.Blog;
  locale: SupportedLocales;
};

const BlogTeaser = ({ blog, locale }: BlogTeaserProps) => {
  const bgImage = blog.Hero.background_image;
  return (
    <CardTeaser
      url={getBlogItemUrl(blog.slug, locale)}
      title={blog.Hero.title}
      description={blog.Hero.text ?? ""}
      image={
        bgImage?.data != null && Array.isArray(bgImage?.data)
          ? bgImage?.data[0].attributes
          : bgImage?.data?.attributes
      }
    />
  );
};

export { BlogTeaser };
