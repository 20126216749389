import { APITypes } from "@lib/api.types";
import { SanitizeString } from "@lib/SanitizeString";
import { Animation, Button, Image } from "components";
import Link from "next/link";
import React from "react";

interface CardTeaserProps {
  image?: APITypes.Components.Media;
  animation?: string;
  url?: string;
  title: string;
  description?: string;
  buttonText?: string;
  buttonLink?: string;
  style?: "extended" | "default";
  mirrored?: boolean;
  squareImage?: boolean;
}

const CardTeaser = ({
  image,
  url = "",
  title,
  description = "",
  buttonText = "",
  style = "default",
  mirrored = false,
  squareImage = false,
}: CardTeaserProps) => {
  const isExtended = style === "extended";

  let animation;
  if (image && image.url && image.url.includes(".json")) {
    animation = image.url;
  }

  const content = (
    <React.Fragment>
      {image && !animation && (
        <div
          className={`card-teaser-image ${isExtended ? "is-extended" : ""} ${
            squareImage ? "is-square" : ""
          }`}
        >
          <Image className="" image={image} layout="fill" alt={title} />
        </div>
      )}
      {animation && (
        <div className="bg-white">
          <Animation src={animation} />
        </div>
      )}

      <div
        className={`
          flex-grow flex-shrink
          px-4 pt-6 pb-8 sm:px-6 |
          ${isExtended ? "md:p-12 md:pb-16" : ""}
          bg-brand-dark-blue text-white |
          card-teaser-content-cutout
        `}
      >
        <div className="font-bold text-2xl| transition-colors">{title}</div>
        {description && (
          <div className="mt-2 | font-light">
            <SanitizeString content={description} />
          </div>
        )}
        {buttonText && (
          <div className="mt-14">
            <Button size="small" color="green" as="div" icon="arrow-right">
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );

  if (!url) {
    return (
      <div
        className={`group flex flex-col | min-h-full | card-teaser-wrapper ${
          isExtended &&
          "md:grid md:grid-cols-2 md:items-center extended-card-teaser"
        } ${mirrored ? "is-mirrored" : ""}`}
      >
        {content}
      </div>
    );
  }
  return (
    <Link href={url}>
      <a
        className={`group flex flex-col | min-h-full | card-teaser-wrapper ${
          isExtended &&
          "md:grid md:grid-cols-2 md:items-center extended-card-teaser"
        } ${mirrored ? "is-mirrored" : ""}`}
      >
        {content}
      </a>
    </Link>
  );
};

export { CardTeaser };
